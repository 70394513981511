import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./index.scss";

/*
Image component
INPUT
REQUIRED: source,
OPTIONAL: className, alt, placeholder, error

OUTPUT
  Component div.img
*/

export default function Image(props) {
  const { source, alt, className, size, pictureSize } = props;

  let src = source; // we create a new variable, for the moment it just takes the base URL

  if (pictureSize) {
    let prefix = pictureSize.find((ps) => ps.id === size).prefix; // if we have pictureSize and we find it
    src = prefix + source; // we create a new URL mixing the base & the prefix
  }

  return (
    <div className={className}>
      <LazyLoadImage src={src} alt={alt} />
    </div>
  );
}
