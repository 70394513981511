import React, { Component } from "react";

import WebMercatorViewport from "viewport-mercator-project";

// import Currency from '../currency';

import MarkerPin from "./marker";

// import { getSystemSettings } from '../../utils/SettingsRetriever';

// import themeVariables from '../Layout/Themes/themeVariables';

import styles from "./styles.module.scss";
import MapGL, { Marker, Popup, NavigationControl } from "react-map-gl";
import mapboxgl from "mapbox-gl";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const TOKEN =
  "pk.eyJ1Ijoic3BvdGxpbyIsImEiOiJjam51MnJzbTEwd3VuM3BsZWoyaDFjeWVkIn0.XDA_557e1cKki5sBpZFE7w";

export default class Map extends Component {
  state = {
    viewport: {
      width: this.props.width,
      height: this.props.height,
    },
    markers: [],
    arrayPoints: [],
    popupInfo: null,
    pinColor: "red",
    //   pinColor: themeVariables(getSystemSettings()).item_lodging_map_color
  };

  _updateViewport = (viewport) => this.setState({ viewport });

  componentDidMount() {
    const { items, marker } = this.props;
    if (items) {
      if (items.length === 1) {
        this.putMarker(items[0]);
      } else {
        this.updateMap(items);
      }
    } else if (marker) {
      this.putMarker(marker);
    }
  }

  componentDidUpdate(prevProps) {
    const { items, marker } = this.props;
    if (items && prevProps.items !== items) {
      if (items.length === 1) {
        this.putMarker(items[0]);
      } else {
        this.updateMap(items);
      }
    } else if (marker && prevProps.marker !== marker) {
      this.putMarker(marker);
    }
  }

  updateMap = (markers) => {
    let arrayPoints = [];
    let arrayMarkers = [];
    let newViewport = this.state.viewport;
    let bottomLeft = [undefined, undefined];
    let topRight = [undefined, undefined];

    markers.forEach((marker, index) => {
      if (
        marker.latitude !== null &&
        marker.latitude !== 0 &&
        marker.latitude < 90 &&
        marker.latitude > -90
      ) {
        if (marker.latitude < bottomLeft[1] || bottomLeft[1] === undefined) {
          bottomLeft[1] = marker.latitude;
        }
        if (marker.latitude > topRight[1] || topRight[1] === undefined) {
          topRight[1] = marker.latitude;
        }
        if (marker.longitude < bottomLeft[0] || bottomLeft[0] === undefined) {
          bottomLeft[0] = marker.longitude;
        }
        if (marker.longitude > topRight[0] || topRight[0] === undefined) {
          topRight[0] = marker.longitude;
        }

        // marker.Supplier.color = marker.Neighborhood ? marker.Neighborhood.color : this.state.pinColor
        let markerColor = this.state.pinColor || "#000000";
        arrayPoints.push([marker.longitude, marker.latitude]);
        marker.index = index;

        arrayMarkers.push(
          <Marker
            key={`marker-${index}`}
            longitude={marker.longitude}
            latitude={marker.latitude}
          >
            <MarkerPin
              number={index}
              color={markerColor}
              size={20}
              onClick={() => this.setState({ popupInfo: marker })}
            />
          </Marker>
        );
      }
    });

    if (arrayPoints.length > 1) {
      let corners = [bottomLeft, topRight];
      const viewport = new WebMercatorViewport({
        width: this.props.viewPortWidth,
        height: this.props.viewPortHeight,
      });
      const bound = viewport.fitBounds(corners, { padding: 20 });
      newViewport.latitude = bound.latitude;
      newViewport.longitude = bound.longitude;
      newViewport.zoom = bound.zoom;
    }

    newViewport.height = this.props.height;
    newViewport.width = this.props.width;

    this.setState({ markers: arrayMarkers, viewport: newViewport });
  };

  putMarker = (marker) => {
    if (marker && marker.latitude !== null && marker.longitude !== null) {
      let arrayMarkers = [];
      let viewport = this.state.viewport;
      viewport.latitude = marker.latitude;
      viewport.longitude = marker.longitude;
      viewport.zoom = 15;
      arrayMarkers.push(
        <Marker
          key={`marker-${marker.name}`}
          // key={`marker-${marker.Supplier.SupplierName}`}
          longitude={marker.longitude}
          latitude={marker.latitude}
        >
          <MarkerPin
            color={this.state.pinColor}
            size={24}
            onClick={() => this.setState({ popupInfo: marker })}
          />
        </Marker>
      );
      this.setState({ markers: arrayMarkers, viewport: viewport });
    }
  };

  _renderPopup() {
    const { popupInfo } = this.state;

    if (this.props.update === false || !popupInfo) return null;
    return (
      <Popup
        tipSize={5}
        anchor="bottom"
        offsetTop={-15}
        longitude={popupInfo.longitude}
        latitude={popupInfo.latitude}
        closeOnClick={false}
        onClose={() => this.setState({ popupInfo: null })}
      >
        <div className={styles.popupContent}>
          <h3 className={styles.popupTitle}>{popupInfo.name}</h3>
        </div>
      </Popup>
    );
  }

  render() {
    const { onClick, className, display, disabled } = this.props;

    let style = { display: "none" };
    if (display) style.display = "inherit";
    if (disabled) style.pointerEvents = "none";

    return (
      <div
        onClick={onClick ? () => onClick() : null}
        style={style}
        className={className}
      >
        <MapGL
          fitBounds
          {...this.state.viewport}
          width={this.props.width}
          height={this.props.height}
          mapStyle="mapbox://styles/mapbox/outdoors-v11"
          onViewportChange={this._updateViewport}
          mapboxApiAccessToken={TOKEN}
        >
          {this.props.navigationContrl === false ? null : (
            <div
              className="navigationButtons"
              style={{ position: "absolute", left: 15, top: 15 }}
            >
              <NavigationControl />
            </div>
          )}
          {this.state.markers}
          {this._renderPopup()}
        </MapGL>
      </div>
    );
  }
}
