import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import styles from "./styles.module.scss";

export default function AlertDialog(props) {
  const { open, close, title, description, buttonText, onClick } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "14px",
            width: "100%",
          },
        }}
      >
        <div className={styles.container}>
          {title && (
            <div className={styles.title} id="alert-dialog-title">
              {title}
            </div>
          )}
          {description && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {description}
              </DialogContentText>
            </DialogContent>
          )}
          <div onClick={onClick} className={styles.mainButton}>
            <span>{buttonText}</span>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
