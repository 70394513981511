import React from "react";
import QRCode from "qrcode.react";
import PushHistory from "../../../utils/pushHistory";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import EventBus from "../../../utils/eventBus";

import Layout from "../../../components/vilaloving/layout";
import Icon from "../../../components/icon";
import Map from "../../../components/map";
import Loading from "../../../components/loadingAnimation";

import styles from "./styles.module.scss";
import serviceprovider from "../../../helpers/serviceprovider";
import Carousel from "../../../components/carousel/index";
import Modal from "../../../components/vilaloving/modal";
import {
  getLocalStorage,
  removeLocalStorage,
} from "../../../utils/localStorage";
import {
  CLEAR_HEADER,
  COUNTDOWN_TIME_CHANGE,
  DISCOUNT_CHANGE,
} from "../../../utils/events";
import experiences from "../../../helpers/experiences";
import vilalovings from "../../../helpers/vilalovings";
import localeAsString from "../../../utils/localeAsString";
import { APP } from "../../../utils/definitions";
import { navigateToCurrentStatus } from "../../../helpers/currentStatus";

const colors = {
  shopping: "#1F71B4",
  activities: "#3DA897",
  gastronomy: "#D8004D",
};

export default class VilalovingParticipantSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      participant: null,
      qrCode: null,
      participants: null,
      displayModalMap: false,
      submit: false,
    };
  }

  async componentDidMount() {
    const { history } = this.props;
    if (history && history.location) {
      if (!history.location.state || !history.location.state.redirect) {
        await navigateToCurrentStatus();
      }
    }

    const option = this.props.match.params.option;
    const currentVilaloving = await vilalovings.getCurrentVilaloving();
    const currentExperience = await experiences.getCurrentExperience();

    if (currentVilaloving && currentExperience) {
      EventBus.$emit(COUNTDOWN_TIME_CHANGE, {
        expired_at: new Date(currentVilaloving.expired_at).getTime(),
        created_at: new Date(currentVilaloving.created_at).getTime(),
      });
      EventBus.$emit(DISCOUNT_CHANGE, {
        discount: currentExperience.discount,
      });

      const participantData = experiences.transformExperienceToParticipant(
        currentExperience
      );

      this.setState({
        qrCode: currentExperience.qr,
        participant: participantData,
        option: currentExperience.category,
      });
    } else if (option) {
      const participants = await serviceprovider.getAllByType(option);
      this.setState({ option: option, participants: participants });
    }
  }

  componentDidUpdate = async () => {
    if (this.state.option && this.state.participants === null) {
      const participants = await serviceprovider.getAllByType(
        this.state.option
      );
      this.setState({ participants: participants });
    }
  };

  // Aquí pasamos todos los datos del participante seleccionado, el propio state
  // ya pasará todo lo que necesita en el render
  selectParticipant = (data, option) => {
    const participantData = this.getParticipantData(data, option);
    this.setState({ participant: participantData });
  };

  selectRandomParticipant = () => {
    const { participants } = this.state;
    const random =
      Math.floor(Math.random() * (this.state.participants.length - 1 - 0 + 1)) +
      0;
    this.selectParticipant(participants[random]);
  };

  // Participant a null y ya, el render hace el resto
  unselectParticipant = () => {
    this.setState({ participant: null });
  };

  // Crea el QR code
  generateDiscountQR = async (participant) => {
    await this.setState({ submit: true });
    const userData = getLocalStorage("userData");
    const expireDate = this.calculateExpireDate();
    let currentVilaloving = await vilalovings.getCurrentVilaloving();

    if (!currentVilaloving) {
      const vilalovingData = {
        status: "enabled",
        expired_at: expireDate.toISOString(),
        idUser: userData.id,
      };

      currentVilaloving = await vilalovings.createVilaloving(vilalovingData);
    }

    const completedExperiences = await vilalovings.getVilalovingCompletedExperiencesCount();
    const discount = (completedExperiences + 1) * 5;
    const experience = {
      qr: "",
      category: participant.option || this.props.match.params.option,
      discount: discount,
      status: "enabled",
      idUser: userData.id,
      idProvider: participant.id,
      gallery: participant.images,
      address: participant.address,
      latitude: participant.marker.latitude * 1,
      longitude: participant.marker.longitude * 1,
      name: participant.username,
      description: participant.description,
    };
    let currentExperience = await experiences.createExperience(experience);
    currentVilaloving.experiences.push(currentExperience.id);
    await vilalovings.updateVilaloving(currentVilaloving);

    let qr = `${userData.id};${currentVilaloving.id};${currentExperience.id};${currentExperience.discount};${currentVilaloving.expired_at}`;
    let buff = new Buffer(qr);
    let base64qr = "VILALOVING;" + buff.toString("base64");

    currentExperience.qr = base64qr;

    await experiences.updateExperience(currentExperience);

    console.log("QR code creado!");
    if (completedExperiences === 0) {
      EventBus.$emit(COUNTDOWN_TIME_CHANGE, {
        expired_at: expireDate.getTime(),
        created_at: new Date(currentVilaloving.created_at).getTime(),
      });
    }
    EventBus.$emit(DISCOUNT_CHANGE, { discount: currentExperience.discount });

    // Coger del data el nombre, el discount si se tiene que pillar también...
    this.setState({ qrCode: base64qr });
  };

  // Nos cargamos el QR generado para que no pueda ser validado aunque se
  // screenshotee y tal
  removeDiscountQR = async () => {
    let currentExperience = await experiences.getCurrentExperience();
    if (currentExperience) {
      currentExperience.status = "canceled";
      await experiences.updateExperience(currentExperience);
    }

    const completedExperiences = await vilalovings.getVilalovingCompletedExperiencesCount();
    if (completedExperiences === 0) {
      let currentVilaloving = await vilalovings.getCurrentVilaloving();
      if (currentVilaloving) {
        currentVilaloving.status = "canceled";
        await vilalovings.updateVilaloving(currentVilaloving);
        EventBus.$emit(CLEAR_HEADER, { discount: "reset" });
      }
    }

    this.setState({ qrCode: null, participant: null, submit: false });
  };

  // @to-do Quitar la lógica de aquí y ponerlas en la galaxy api. Aqui solo hacer el redirect en caso de completed
  // Comprobamos si el QR code ha sido validado si está validado siguiente pantalla
  // si no lo está pues mostramos un modal de NOPE
  isQRCodeValidated = async () => {
    try {
      let currentExperience = await experiences.getCurrentExperience();
      currentExperience.status = "completed";
      await experiences.updateExperience(currentExperience);

      const completedExperiences = await vilalovings.getVilalovingCompletedExperiencesCount();
      if (completedExperiences === 3) {
        let currentVilaloving = await vilalovings.getCurrentVilaloving();
        currentVilaloving.status = "completed";
        await vilalovings.updateVilaloving(currentVilaloving);
        removeLocalStorage("vilaloving");
        removeLocalStorage("experience");
        return PushHistory(APP.VLVCOMPLETED, false, { redirect: true });
      }

      PushHistory(`${APP.EXPCOMPLETED}/${this.state.option}`, false, {
        redirect: true,
        experienceId: currentExperience.id,
      });
    } catch (error) {
      return null;
    }
  };

  calculateExpireDate() {
    let day = new Date().getDay();
    let isWeekend = day === 6 || day === 0;
    let expiredIn = 1;
    if (isWeekend) {
      expiredIn = 2;
    }
    const expiredAt = new Date();
    expiredAt.setDate(new Date().getDate() + expiredIn);

    return expiredAt;
  }

  getParticipantData(participant, option) {
    let images = [];
    let description = "";
    let marker = null;

    if (participant) {
      if (participant.Media && participant.Media.length > 0) {
        participant.Media.forEach((img) => images.push(img.image));
      } else {
        images.push(participant.image);
      }
      participant.images = images;

      if (
        participant["UsersName"] &&
        participant["UsersName"][1] &&
        participant["UsersName"][1].description
      ) {
        description = participant["UsersName"][1].description.replace(
          /(?:\r\n|\r|\n)/g,
          "<br/>"
        );
        participant.description = description;
      }

      if (
        participant.latitud &&
        participant.longitud &&
        participant.service_name
      ) {
        marker = {
          latitude: participant.latitud * 1,
          longitude: participant.longitud * 1,
          name: participant.service_name,
        };
      }
      participant.marker = marker;
      participant.option = option;
    }
    return participant;
  }

  displayModalMap = () =>
    this.setState({ displayModalMap: !this.state.displayModalMap });

  render() {
    const {
      option,
      participant,
      participants,
      qrCode,
      displayModalMap,
      submit,
    } = this.state;

    //let participantData = this.getParticipantData(participant, option);

    return (
      <Layout backgroundColor={colors[option]} toggleClock={true} logged>
        {participant ? (
          <div
            className={`${styles.partcipantData} ${
              qrCode ? styles.qrCode : ""
            }`}
          >
            <div className={styles.carousel}>
              <Carousel
                options={{
                  slidesPerPage: 3,
                  arrowLeft: null,
                  arrowRight: null,
                  centered: true,
                  offset: 0,
                }}
              >
                {participant.images.map((img, id) => {
                  return (
                    <div
                      className={styles.image}
                      style={{ backgroundImage: `url(${img})` }}
                      key={`carousel_slide_${id}`}
                    />
                  );
                })}
              </Carousel>
            </div>
            <div className={styles.name}>{participant.service_name}</div>
            <div className={styles.mapWrapper}>
              <div className={styles.map} onClick={this.displayModalMap}>
                <Map
                  width={"100%"}
                  height={100}
                  items={[participant.marker]}
                  viewPortWidth={800}
                  viewPortHeight={800}
                  display={true}
                  disabled
                  navigationContrl={false}
                />
              </div>
              <div className={styles.mapAddress}>{participant.address}</div>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: participant.description }}
              />
              <Modal
                open={displayModalMap}
                closeFn={this.displayModalMap}
                contentClass={"map"}
              >
                <Map
                  width={"100%"}
                  height={window.innerHeight - 109}
                  items={[participant.marker]}
                  viewPortWidth={800}
                  viewPortHeight={800}
                  display={true}
                  className={styles.modalMap}
                  //onPopupClick={this.rowClick}
                />
              </Modal>
            </div>
            {qrCode ? (
              <div
                className={styles.bottomQR}
                style={{ backgroundColor: colors[option] }}
              >
                <div className={styles.bottomQRWrapper}>
                  <div className={styles.QRBox}>
                    <div className={styles.QRlive} />
                    <div className={styles.QRCode}>
                      <QRCode value={qrCode} />
                    </div>
                    <div className={styles.QRDescription}>
                      <div
                        className={styles.description}
                        onClick={() => this.isQRCodeValidated()}
                      >
                        {localeAsString(
                          "master_vilaloving_participants_validate_qr"
                        )}
                      </div>
                      <div
                        className={styles.change}
                        onClick={() => this.removeDiscountQR()}
                      >
                        {localeAsString(
                          "master_vilaloving_participants_change_experience"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={styles.bottomCTA}
                style={{ backgroundColor: colors[option] }}
              >
                <div className={styles.bottomCTAWrapper}>
                  <div
                    className={styles.backButton}
                    onClick={this.unselectParticipant}
                  >
                    <Icon icon="arrow-left" />
                  </div>
                  <div
                    className={
                      submit
                        ? `${styles.randomizerButton} ${styles.disabled}`
                        : styles.randomizerButton
                    }
                    onClick={() => this.generateDiscountQR(participant)}
                  >
                    {submit ? (
                      <Loading />
                    ) : (
                      localeAsString(
                        "master_vilaloving_participants_start_experience"
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={styles.participantsDesc}>
              <span>
                {localeAsString(
                  "master_vilaloving_participants_select_a_local"
                )}
              </span>
            </div>
            {!participants ? (
              <SkeletonTheme color="#e1e1e1" highlightColor="#ededed">
                <div className={styles.participants}>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((id) => {
                    return (
                      <div
                        className={styles.participant}
                        key={`participant_skeleton_${id}`}
                        style={{ opacity: 0.5 }}
                      >
                        <div
                          className={styles.photoContainer}
                          style={{ border: "none", background: "none" }}
                        >
                          <div className={styles.photo}>
                            <Skeleton
                              width={"100%"}
                              height={"100%"}
                              duration={1}
                            />
                          </div>
                        </div>
                        <div className={styles.name}>
                          <Skeleton
                            width={"100%"}
                            height={"100%"}
                            duration={1}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SkeletonTheme>
            ) : (
              <div className={styles.participants}>
                {participants.map((data, id) => {
                  return (
                    <div
                      className={styles.participant}
                      onClick={() => this.selectParticipant(data, option)}
                      key={`participant_${id}`}
                    >
                      <div className={styles.photoContainer}>
                        <img
                          src={data.image}
                          alt={"participant"}
                          className={styles.photo}
                        />
                      </div>
                      <div className={styles.name}>{data.service_name}</div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              className={styles.bottomCTA}
              style={{ backgroundColor: colors[option] }}
            >
              <div className={styles.bottomCTAWrapper}>
                <div
                  className={styles.backButton}
                  onClick={() => PushHistory(APP.ROULETTE)}
                >
                  <Icon icon="arrow-left" />
                </div>
                <div
                  className={styles.randomizerButton}
                  onClick={() => {
                    if (participants) this.selectRandomParticipant();
                  }}
                >
                  {localeAsString("master_vilaloving_roulette_random")}
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    );
  }
}
