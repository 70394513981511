import React from "react";
import Modal from "@material-ui/core/Modal";

import Icon from "../../../components/icon";

import styles from "./styles.module.scss";

const modal = ({ open, children, closeFn, className, contentClass }) => {
  const containerClassName = styles[className] || styles.fullscreen;
  const contentClassName = styles[contentClass] || styles.defaultContent;

  return (
    <Modal
      open={open}
      disablePortal={true}
      onBackdropClick={closeFn}
      onEscapeKeyDown={closeFn}
      className={containerClassName}
    >
      <div className={contentClassName}>
        {children}
        {closeFn && (
          <div className={styles.closeModal}>
            <Icon
              onClick={closeFn}
              className={styles.closeModalIcon}
              icon="FiX"
              size={25}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default modal;
