import React from "react";

import Carousel from "./coreCarousel";
import BrainhubeuCarousel from "./brainhubeu";

const mockedCarousel = "brainhubeu";
export default class DemoCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  onChange = (value) => {
    this.setState({ value });
  };

  render() {
    const { state, onChange, props } = this;
    const { children, options, galleryButton } = props;
    const { value } = state;

    switch (mockedCarousel) {
      case "brainhubeu":
        return (
          <BrainhubeuCarousel
            options={options}
            selectedItem={value}
            onChange={onChange}
            galleryButton={galleryButton}
          >
            {children}
          </BrainhubeuCarousel>
        );
      default:
        return (
          <Carousel
            options={options}
            selectedItem={value}
            onChange={onChange}
            galleryButton={galleryButton}
          >
            {children}
          </Carousel>
        );
    }
  }
}
