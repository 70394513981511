import React from "react";
import Clock from "./clock";
import EventBus from "../../../utils/eventBus";
import { COUNTDOWN_TIME_FINISHED } from "../../../utils/events";

export default class ClockPercentage extends React.Component {
  state = {
    percentage: null,
    error: false,
  };

  stopped = false;

  componentDidMount() {
    const { expiredDate, createdDate } = this.props;
    if (!expiredDate || !createdDate) return;

    this.updatePercentage();
  }

  componentDidUpdate(prevProps) {
    const { expiredDate, createdDate } = this.props;

    if (
      expiredDate &&
      createdDate &&
      prevProps.expiredDate !== expiredDate &&
      prevProps.createdDate !== createdDate
    ) {
      this.updatePercentage();
    }
  }

  componentWillUnmount() {
    this.stopped = true;
  }

  updatePercentage = () => {
    if (this.stopped) return;

    const { expiredDate, createdDate } = this.props;

    // Get today's date and time
    let now = new Date().getTime();

    // Find the distance between now and the count down date
    let distance = expiredDate - now;

    const vilalovingAvailableHours = expiredDate - createdDate;

    const percentage = (distance / vilalovingAvailableHours) * 100;

    // If the count down is finished...
    if (distance < 0) {
      this.stopped = true;
      EventBus.$emit(COUNTDOWN_TIME_FINISHED, null);
      return;
    }

    // Update the percentage every minute
    this.setState({ percentage }, () =>
      setTimeout(this.updatePercentage, 60000)
    );
  };

  render() {
    const { percentage, error } = this.state;
    if (error || percentage === null) return null;

    return <Clock percentage={percentage} />;
  }
}
