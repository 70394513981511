import React from "react";
import Lottie from "react-lottie";
import animationData from "./loading.json";

export default function SnowingAnimation(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <div className="loadingAnimation">
        <div className="loading">
          <Lottie
            options={defaultOptions}
            height={20}
            width={20}
            isStopped={false}
            isPaused={false}
            className="lottie"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
