const queryString = () => {
    if (window.location.href.indexOf("?") > 0) {
      const queryString = window.location.href.split("?")[1].split("&");
      if (queryString !== undefined) {
        let ret = {};
        queryString.forEach((idx) => {
          const param = idx.split("=");
          const key = param[0];
          let value = decodeURIComponent(param[1]);
          try {
            value = JSON.parse(value);
          } catch {} // die silently
          ret[key] = value;
        });
        return ret;
      } else {
        return {};
      }
    } else {
      return {};
    }
};
  
export default queryString;
  