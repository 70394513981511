import { API } from "../utils/definitions";
import auth from "./auth";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../utils/localStorage";

class Vilalovings {
  createVilaloving(data) {
    return auth
      .requestWithBearer("post", `${API.VILALOVINGS}`, data)
      .then(async (vilalovingData) => {
        removeLocalStorage("vilaloving");
        const currentVilaloving = await this.getCurrentVilaloving();
        if (currentVilaloving) {
          setLocalStorage("vilaloving", currentVilaloving);
        }
        return Promise.resolve(vilalovingData);
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  getCurrentVilaloving() {
    const userData = auth.getStrapiUserStorage();
    if (!userData || !userData.id) {
      return null;
    }
    const currentVilaloving = getLocalStorage("vilaloving");

    if (currentVilaloving && currentVilaloving.status === "enabled") {
      return currentVilaloving;
    }

    const url = `${API.VILALOVINGS}/?status=enabled&idUser=${userData.id}`;

    return auth
      .requestWithBearer("get", url)
      .then((vilalovingData) => {
        if (
          vilalovingData &&
          Array.isArray(vilalovingData) &&
          vilalovingData.length > 0
        ) {
          return Promise.resolve(vilalovingData[0]);
        }
        return null;
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  async getCurrentVilalovingHeaderData() {
    const data = {};
    const currentVilaloving = await this.getCurrentVilaloving("vilaloving");
    if (
      currentVilaloving &&
      currentVilaloving.status === "enabled" &&
      currentVilaloving.expired_at &&
      currentVilaloving.created_at &&
      Array.isArray(currentVilaloving.experiences)
    ) {
      // dates
      data.expired_at = new Date(currentVilaloving.expired_at).getTime();
      data.created_at = new Date(currentVilaloving.created_at).getTime();

      // experience discount
      const completedExperiences = currentVilaloving.experiences.filter(
        (exp) => exp.status === "completed"
      );

      const discount = (completedExperiences.length + 1) * 5;
      if (discount) {
        data.discount = discount;
      }
    }
    return data;
  }

  async getVilalovingCompletedExperiencesCount() {
    const currentVilaloving = await this.getCurrentVilaloving();
    if (currentVilaloving && Array.isArray(currentVilaloving.experiences)) {
      const completedExperiences = currentVilaloving.experiences.filter(
        (exp) => exp.status === "completed"
      );
      return completedExperiences.length;
    } else {
      return 0;
    }
  }

  async vilalovingCompletedExperiences(type) {
    const currentVilaloving = await this.getCurrentVilaloving();
    let categories = [];
    if (currentVilaloving && Array.isArray(currentVilaloving.experiences)) {
      const completedExperiences = currentVilaloving.experiences.filter(
        (exp) => exp.status === "completed"
      );
      categories = completedExperiences.map(
        (experience) => experience.category
      );
    }
    return categories;
  }

  updateVilaloving(data) {
    return auth
      .requestWithBearer("put", `${API.VILALOVINGS}/${data.id}`, data)
      .then(async (vilalovingData) => {
        removeLocalStorage("vilaloving");
        const currentVilaloving = await this.getCurrentVilaloving();
        if (currentVilaloving) {
          setLocalStorage("vilaloving", currentVilaloving);
        }
        return Promise.resolve(vilalovingData);
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }
}

export default new Vilalovings();
