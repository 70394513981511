import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import styles from "./loader.module.scss";

const animationDuration = 1;

function Loader() {
  return (
    <SkeletonTheme color="#e1e1e1" highlightColor="#ededed">
      <div className={styles.headerWrapper}>
        <Skeleton
          className={styles.headerLoader}
          width={"100%"}
          height={"525px"}
          duration={animationDuration}
        />
        <div className={styles.headerContent}>
          <div className={styles.appIconWrapper}>
            <div className={styles.appIcon}>&nbsp;</div>
          </div>
          <div className={styles.downloadWrapper}>
            <div className={styles.line}>&nbsp;</div>
            <div className={styles.line}>&nbsp;</div>
            <div className={styles.line}>&nbsp;</div>

            <div className={styles.downloadIcons}>
              <div className={styles.downloadPlayStore}>&nbsp;</div>
              <div className={styles.downloadAppStore}>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default Loader;
