import React from "react";
import { Container } from "@material-ui/core";

import Layout from "./../../components/layout";
import SumupValue from "./../../components/kpi/SumupValue";
import List from "./../../components/kpi/List";

import styles from "./styles.module.scss";

export default class KPI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listValues: {
        title: ["Value 1", "Value 2", "Value 3", "Value 4"],
        other_title: ["Value 1", "Value 2", "Value 3"],
      },
    };
  }

  render() {
    return (
      <Layout>
        <Container maxWidth={false}>
          <div className={styles.content}>
            <div className={styles.sumupValues}>
              <SumupValue
                title={"Title"}
                value={"Value contentcontentcontentcontent"}
              />
              <SumupValue title={"Title"} value={"Value content"} />
              <SumupValue title={"Title"} value={"Value content"} />
              <SumupValue title={"Title"} value={"Value content"} />
              <SumupValue title={"Title"} value={"Value content"} />
              <SumupValue title={"Title"} value={"Value content"} />
            </div>
            <div className={styles.lists}>
              <List data={this.state.listValues} />
            </div>
            <div className={styles.lists}>
              <List listTitle={"List title"} data={this.state.listValues} />
            </div>
            <div className={styles.lists}>
              <List listTitle={"List title"} data={this.state.listValues} />
            </div>
          </div>
        </Container>
      </Layout>
    );
  }
}
