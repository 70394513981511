const events = {};
const actions = {
  $emit: (event, params) => {
    // process.env.NODE_ENV === 'development' && console.log("event:emit", event, params);
    const dispatchEvent = new CustomEvent(event, { detail: params });
    if (events[event]) {
      window.addEventListener(event, events[event]);
      window.dispatchEvent(dispatchEvent);
      window.removeEventListener(event, events[event]);
    }
  },
  $on: (event, callback) => {
    // process.env.NODE_ENV === 'development' && console.log("event:on", event);
    window.addEventListener(event, callback);
    events[event] = callback;
  },
  $off: (event) => {
    // process.env.NODE_ENV === 'development' && console.log("event:off", event);
    window.removeEventListener(event, () => {});
    delete events[event];
  },
};

export default actions;
