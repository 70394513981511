import React from "react";

import styles from "./styles.module.scss";

export default class Clock extends React.Component {
  componentDidMount() {
    this.clockProgress(this.props.percentage);
  }

  componentDidUpdate(prevProps) {
    const { percentage } = this.props;
    if (percentage && prevProps.percentage !== percentage) {
      this.clockProgress(this.props.percentage);
    }
  }

  clockProgress = (value) => {
    const circle = document.getElementById("clockCircle");
    let radius = circle.r.baseVal.value;
    let circumference = radius * 2 * Math.PI;

    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;

    function setProgress(percent) {
      const offset = circumference - (percent / 100) * circumference;
      circle.style.strokeDashoffset = -offset;
    }

    setProgress(value);
  };

  render() {
    return (
      <svg
        className={styles["progress-ring"]}
        width="22"
        height="22"
        style={{ overflow: "visible" }}
      >
        <path
          d="M1 1V7L4 10"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(9.5, 4.5)"
        />
        <circle
          className={styles["progress-ring__circle_fill"]}
          stroke="#00000080"
          strokeWidth="3"
          fill="transparent"
          r="10"
          cx="11"
          cy="11"
        />
        <circle
          id="clockCircle"
          className={styles["progress-ring__circle"]}
          stroke="white"
          strokeWidth="3"
          fill="transparent"
          r="10"
          cx="11"
          cy="11"
        />
      </svg>
    );
  }
}
