import React from "react";
import Layout from "../../../components/layout";
import Button from "../../../components/button";
import Icon from "../../../components/icon";
import Modal from "../../../components/modal";
import QS from "../../../utils/queryString";

import CA from "../../../languages/ca.json";
import EN from "../../../languages/en.json";
import ES from "../../../languages/es.json";
import FR from "../../../languages/fr.json";
import styles from "./tutorial.module.scss";

export default class Baqueira extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: EN,
      modal: false,
      buttons: [
        {
          text: "tutorial.baqueira.login",
          video: "https://youtube.com/embed/w4uM-9xVLIg", // "https://www.youtube.com/embed/URYPYwOO0rI",
        },
        {
          text: "tutorial.baqueira.buy",
          video: "https://youtube.com/embed/D8PRP5uUauw", //https://www.youtube.com/embed/i4ncAJhHR7A",
        },
        {
          text: "tutorial.baqueira.revo",
          video: "https://youtube.com/embed/IMOWXFTgB08", //"https://drive.google.com/file/d/1QnwBTEV5r0aWezsEdB7evHThNrMxOnlX/preview",
        },
        {
          text: "tutorial.baqueira.track",
          video: "https://youtube.com/embed/ZiEbmrHuOyg", //"https://drive.google.com/file/d/1QnwBTEV5r0aWezsEdB7evHThNrMxOnlX/preview",
        },
      ],
    };
  }

  componentDidMount() {
    const lang = this.language(QS().language_id);
    this.setState({ lang });
  }

  toggleModal = (idx) => {
    if (this.state.modal !== idx) this.setState({ modal: idx });
  };

  language = (lang) => {
    switch (lang) {
      case 0:
        return ES;
      case 1:
        return CA;
      case 2:
        return EN;
      case 3:
        return FR;
      default:
        return ES;
    }
  };

  render() {
    const { lang, buttons, modal } = this.state;
    return (
      <Layout>
        <div className={styles.content}>
          <div className={styles.icon}>
            <Icon icon={"QuestionMark"} size={125} className={styles.icon} />
          </div>
          <div className={styles.category}>
            <p className={styles.title}>
              <strong>{lang["tutorial.tutorial"]}</strong>
            </p>
            <p className={styles.description}>
              <span>{lang["tutorial.baqueira.description"]}</span>
            </p>
          </div>
          <div className={styles.options}>
            {buttons.map((button, idx) => {
              return (
                <>
                  <Button
                    className={styles.button}
                    onClick={() => this.toggleModal(idx)}
                  >
                    {lang[button.text]}
                  </Button>
                  <Modal
                    open={modal === idx}
                    closeFn={() => this.toggleModal(false)}
                  >
                    <iframe
                      title={`Video tutorial for the option ${idx + 1}`}
                      src={button.video}
                      width="100%"
                      height="100%"
                    ></iframe>
                  </Modal>
                </>
              );
            })}
          </div>
        </div>
      </Layout>
    );
  }
}
