import { API } from "../utils/definitions";
import { setLocalStorage, getLocalStorage } from "../utils/localStorage";
import logged from "./logged";
import requester from "./requester";
import md5 from "md5";

class Auth {
  queryParamUserId = null;

  async strapiLogin() {
    requester
      .post(`${API.LOGIN}`, {
        email: "systems@spotlio.com",
        password: "#0VxmHjo&9J0Z2PilOjzRw4dfA^rjQK3y0$!WHz5$v1eJi6&o#O842L8",
      })
      .then((loginResult) => {
        let userData = {
          isLogged: true,
          session: {
            token: loginResult.data.token,
            timestamp: new Date().getTime(),
          },
          user: loginResult.data.user,
          permissions: false,
        };

        requester
          .get(`${API.PERMISSIONS}`, {
            headers: {
              Authorization: "Bearer " + loginResult.data.token,
            },
          })
          .then((permissionResult) => {
            userData.permissions = permissionResult.data;
            setLocalStorage("session", userData);
          })
          .catch(() => {
            return "An error ocurred";
          });
      })
      .catch(() => {
        return "An error ocurred";
      });
  }

  getStrapiUserById(userId) {
    return this.requestWithBearer("get", `${API.USER}/?idSpotlio=${userId}`)
      .then((userData) => {
        if (userData && Array.isArray(userData) && userData.length > 0) {
          setLocalStorage("userData", userData[0]);
          return Promise.resolve(userData[0]);
        }
      })
      .catch((err) => {
        // console.log(err);
        return Promise.reject("An error ocurred");
      });
  }

  getStrapiUserStorage() {
    const userData = getLocalStorage("userData");
    return userData;
  }

  loginStrapiUser({ email, password }) {
    const pass = md5(password);
    return this.requestWithBearer(
      "get",
      `${API.USER}/?email=${email}&password=${pass}`
    )
      .then((userData) => {
        if (userData && Array.isArray(userData) && userData.length > 0) {
          setLocalStorage("userData", userData[0]);
          return Promise.resolve(userData[0]);
        }
        return Promise.reject("An error ocurred");
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  strapiRegister(data) {
    if (!data.firstName || !data.lastName || !data.email || !data.password)
      return Promise.reject("Missing required field");
    const finalData = { ...data, password: md5(data.password) };
    return this.requestWithBearer("post", `${API.USER}`, finalData)
      .then((userData) => {
        if (userData) {
          setLocalStorage("userData", userData);
          return Promise.resolve(userData);
        }
        return Promise.reject("An error ocurred");
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  async requestWithBearer(method, url, data) {
    const token = logged.getSessionToken();
    const headers = {
      Authorization: "Bearer " + token,
    };
    try {
      if (method === "get") {
        const response = await requester.get(url, { headers: headers });
        return response;
      }
      if (method === "post") {
        const response = await requester.post(url, data, headers);
        return response;
      }
      if (method === "put") {
        const response = await requester.put(url, data, headers);
        return response;
      }
      if (method === "delete") {
        const response = await requester.delete(url, data, headers);
        return response;
      }
    } catch (e) {
      // console.log(e);
      return null;
    }
  }

  setQueryParamUser(id) {
    this.queryParamUserId = id;
  }

  unsetQueryParamUser() {
    this.queryParamUserId = null;
  }
}

export default new Auth();
