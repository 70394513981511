import React from "react";

import reviews from "../../../helpers/reviews";
import { navigateToCurrentStatus } from "../../../helpers/currentStatus";
import vilalovings from "../../../helpers/vilalovings";
import localeAsString from "../../../utils/localeAsString";

import CashbackAnimation from "../../../components/vilaloving/cashAnimation";
import Layout from "../../../components/vilaloving/layout";
import Rating from "../../../components/vilaloving/rating";
import TextArea from "../../../components/vilaloving/textarea";
import Countdown from "../../../components/vilaloving/header/countdown";

import styles from "./styles.module.scss";
import PushHistory from "../../../utils/pushHistory";
import { APP } from "../../../utils/definitions";
import history from "../../../history";

const colors = {
  shopping: "#1F71B4",
  activities: "#3DA897",
  gastronomy: "#D8004D",
};

export default class VilalovingExperienceCompleted extends React.Component {
  state = {
    option: null,
    review: {
      rate: null,
      comment: "",
      idExperience: null,
    },
    expired_at: null,
    discount: null,
    submit: false,
  };

  async componentDidMount() {
    if (!history.location.state || !history.location.state.redirect) {
      return await navigateToCurrentStatus();
    }
    const option = this.props.match.params.option;
    const experienceId = history.location.state.experienceId;
    if (option && experienceId) {
      const data = {
        option,
        experienceId,
        review: { ...this.state.review, idExperience: experienceId },
      };

      // recuperar los datos del vilaloving actual
      const currentVilaloving = await vilalovings.getCurrentVilalovingHeaderData();
      if (!currentVilaloving) return navigateToCurrentStatus();

      if (currentVilaloving.discount)
        data.discount = currentVilaloving.discount;
      if (currentVilaloving.expired_at)
        data.expired_at = currentVilaloving.expired_at;

      this.setState(data);
    } else {
      await navigateToCurrentStatus();
    }
  }

  parseDiscount(discount) {
    return `-${discount}%`;
  }

  onReviewChanged = async () => {
    const { experienceId, review } = this.state;
    try {
      await reviews.handleReviewChange(experienceId, review);
      // console.log("reviewData", reviewData)
    } catch (error) {
      // console.log("review Issue", error)
    }
  };

  navigateToRoulette = () => PushHistory(APP.ROULETTE);

  handleReviewChange = ({ target: { value } }) => {
    const review = this.state.review;
    review.comment = value;
    this.setState({ review });
  };

  handleRatingChange = (rate) => {
    const review = this.state.review;
    review.rate = rate;
    this.setState({ review }, () => this.onReviewChanged());
  };

  render() {
    const { option, expired_at, discount, submit } = this.state;

    if (!option) return null;

    return (
      <Layout backgroundColor={colors[option]} logged>
        <div className={styles.animationOverlay}>
          <div className={styles.animationScale}>
            <CashbackAnimation />
          </div>
        </div>
        <div className={styles.experienceWrapper}>
          <div className={styles.expTitle}>
            {localeAsString(
              "master_vilaloving_experience_completed_experience"
            )}
          </div>
          <div className={styles.expDesc}>
            <span>
              {localeAsString("master_vilaloving_experience_use_discount")}
            </span>
            <br />({localeAsString("master_vilaloving_experience_valid_for")}:{" "}
            <Countdown countDownDate={expired_at} />
            h):
          </div>
          <div className={styles.cashContainer}>
            {discount && (
              <div className={styles.discount}>
                {this.parseDiscount(discount)}
              </div>
            )}
          </div>
          <div className={styles.rate}>
            {localeAsString("master_vilaloving_experience_rate_experience")}:
          </div>
          <div className={styles.rating}>
            <Rating onChange={this.handleRatingChange} />
          </div>
          {submit ? (
            <div className={styles.submit}>
              {localeAsString("master_vilaloving_experience_comment_sent")}
            </div>
          ) : (
            <div className={styles.textArea}>
              <TextArea
                placeholder={localeAsString(
                  "master_vilaloving_experience_write_comment"
                )}
                name="comment"
                onChange={this.handleReviewChange}
                onBlur={this.handleRatingChange}
                value={this.state.review.comment}
                submit={() => this.setState({ submit: true })}
              />
            </div>
          )}
        </div>
        <div
          className={styles.bottomCTA}
          style={{ backgroundColor: colors[option] }}
        >
          <div className={styles.bottomCTAWrapper}>
            <div
              className={styles.backButton}
              onClick={this.navigateToRoulette}
            >
              {localeAsString("master_vilaloving_experience_leave")}
            </div>
            <div className={styles.button} onClick={this.navigateToRoulette}>
              {localeAsString(
                "master_vilaloving_experience_start_new_experience"
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
