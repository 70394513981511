import React from "react";

import Icon from "../../../components/icon";

import styles from "./styles.module.scss";

export default class VilalovingAnimation extends React.Component {
  render() {
    return (
      <div className={styles.overlay}>
        <Icon
          icon="VilalovingLogoTransition1"
          className={styles.transitionOne}
        />
        <Icon
          icon="VilalovingLogoTransition2"
          className={styles.transitionTwo}
        />
        <Icon
          icon="VilalovingLogoTransition3"
          className={styles.transitionThree}
        />
        <Icon
          icon="VilalovingLogoTransition4"
          className={styles.transitionFour}
        />
      </div>
    );
  }
}
