import React, { PureComponent } from "react";

export default class MarkerPin extends PureComponent {
  render() {
    const { size = 24, onClick } = this.props;
    const pinStyle = {
      cursor: "pointer",
      fill: this.props.color,
    };
    return (
      <svg
        height={size}
        viewBox="0 0 24 28"
        style={{
          ...pinStyle,
          transform: `translate(${-size / 2}px,${-size}px)`,
        }}
        onClick={onClick}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 11C21 18 12 26 12 26C12 26 3 18 3 11C3 6.02944 7.02944 2 12 2C16.9706 2 21 6.02944 21 11Z"
          fill="#009C2A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6713 27.4948L12 26L13.3287 27.4948C12.571 28.1684 11.429 28.1684 10.6713 27.4948ZM12 26C12 26 3 18 3 11C3 6.02944 7.02944 2 12 2C16.9706 2 21 6.02944 21 11C21 18 12 26 12 26ZM13.3287 27.4948C13.3283 27.4952 13.3287 27.4948 12 26C10.6713 27.4948 10.6717 27.4952 10.6713 27.4948L10.6671 27.4911L10.6596 27.4844L10.6351 27.4624C10.6145 27.4438 10.5856 27.4177 10.549 27.3841C10.4757 27.3171 10.3712 27.2205 10.24 27.0966C9.97785 26.849 9.60813 26.4915 9.16662 26.0418C8.28593 25.1448 7.10795 23.8692 5.92499 22.3576C3.64575 19.4453 1 15.227 1 11C1 4.92487 5.92487 0 12 0C18.0751 0 23 4.92487 23 11C23 15.227 20.3542 19.4453 18.075 22.3576C16.8921 23.8692 15.7141 25.1448 14.8334 26.0418C14.3919 26.4915 14.0221 26.849 13.76 27.0966C13.6288 27.2205 13.5243 27.3171 13.451 27.3841C13.4144 27.4177 13.3855 27.4438 13.3649 27.4624L13.3404 27.4844L13.3329 27.4911L13.3287 27.4948Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15C9.79086 15 8 13.2091 8 11ZM12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9Z"
          fill="white"
        />
        {this.props.number !== undefined ? (
          <text
            x="12"
            y="14"
            fontSize="13px"
            fontWeight="500"
            textAnchor="middle"
            stroke="white"
            fill="white"
            strokeWidth="1"
          >
            {parseInt(this.props.number + 1)}
          </text>
        ) : null}
      </svg>
    );
  }
}
