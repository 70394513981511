import React from "react";
import { CssBaseline } from "@material-ui/core";

import styles from "./layout.module.scss";

export default class Layout extends React.Component {
  render() {
    return (
      <div className={styles.Layout}>
        <CssBaseline />
        {this.props.children}
      </div>
    );
  }
}
