import React from "react";

import { checkCurrentStatus } from "../../../helpers/currentStatus";
import localeAsString from "../../../utils/localeAsString";

import Layout from "../../../components/vilaloving/layout";
import VilalovingAnimation from "../../../components/vilaloving/vilalovingAnimation";

import styles from "./styles.module.scss";
import serviceprovider from "../../../helpers/serviceprovider";

export default class VilalovingHome extends React.Component {
  componentDidMount() {
    serviceprovider.getAll();
  }

  checkStatus = async () => {
    await checkCurrentStatus(true);
  };

  render() {
    return (
      <Layout backgroundColor={"#1F3084"}>
        <div className={styles.wrapper}>
          <div className={styles.block}>
            {localeAsString("master_vilaloving_home_title")}
          </div>
          <div className={styles.block}>
            {localeAsString("master_vilaloving_home_description")}
          </div>
          <div className={styles.block}>
            {localeAsString("master_vilaloving_home_description_2")}
          </div>
        </div>
        <div className={styles.bottomCTA}>
          <div className={styles.bottomCTAWrapper}>
            <div className={styles.cta} onClick={() => this.checkStatus()}>
              {localeAsString("master_vilaloving_home_enter")}
            </div>
          </div>
        </div>
        <VilalovingAnimation />
      </Layout>
    );
  }
}
