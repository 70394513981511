import React from "react";
import ReactDOM from "react-dom";

import App from "./containers/App";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import { GTM_CODE } from "./utils/definitions";
import TagManager from "react-gtm-module";

const tagManagerArgs = { gtmId: GTM_CODE };
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
