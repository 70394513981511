import React from "react";
import { Carousel } from "react-responsive-carousel";

import Icon from "../icon";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.scss";

export default class DemoCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  onChange = (value) => {
    this.setState({ value });
  };

  render() {
    const { state, onChange, props } = this;
    const { children, options, galleryButton } = props;
    const { value } = state;

    const defaultOptions = {
      showArrows: false,
      showStatus: false,
      showIndicators: false,
      infiniteLoop: false,
      showThumbs: false,
      useKeyboardArrows: true,
      autoPlay: false,
      stopOnHover: true,
      swipeable: true,
      dynamicHeight: true,
      emulateTouch: true,
      centerSlidePercentage: 100,
    };

    const configOptions = {
      ...defaultOptions,
      ...options,
    };

    return (
      <div className="carousel">
        {galleryButton && (
          <Icon
            size={24}
            icon={"modal-gallery-button"}
            className="galleryButton"
            onClick={() => this.setState({ modal: true })}
          />
        )}
        <Carousel selectedItem={value} onChange={onChange} {...configOptions}>
          {children}
        </Carousel>
      </div>
    );
  }
}
