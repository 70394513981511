import React from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import styles from "./styles.module.scss";

export default class TextArea extends React.Component {
  render() {
    const { placeholder, onBlur, value, name, onChange, submit } = this.props;
    return (
      <div className={styles.textareaWrapper}>
        <TextareaAutosize
          className={styles.textarea}
          rows={2}
          placeholder={placeholder}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          name={name}
        />
        <div className={styles.submitButton} onClick={() => submit()}>
          Envia
        </div>
      </div>
    );
  }
}
