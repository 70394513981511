import React from "react";
import Router from "../router";

export default class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router />
      </React.Fragment>
    );
  }
}
