const STRAPI = "https://api-vilaloving-dev.spotlioapps.com"; // "http://localhost:1337";
const CONNECT = "https://connect-dev.spotlio.com";
const SERVICE_PROVIDERS_ALL =
  "https://ws.spotlio.com/services.php/serviceProvider/list?destination_id=44";

const GTM_CODE = "GTM-TZXK38N";

const API = {
  LOGIN: STRAPI + "/admin/login",
  PERMISSIONS: STRAPI + "/admin/users/me/permissions",
  USER: STRAPI + "/vilaloving-users",
  VILALOVINGS: STRAPI + "/vilalovings",
  EXPERIENCES: STRAPI + "/experiences",
  REVIEWS: STRAPI + "/reviews",
};
const APP = {
  HOME: "/vilaloving/home",
  REGISTER: "/vilaloving/register",
  LOGIN: "/vilaloving/login",
  ROULETTE: "/vilaloving/roulette",
  PARTICIPANTS: "/vilaloving/participants",
  EXPCOMPLETED: "/vilaloving/experience",
  VLVCOMPLETED: "/vilaloving/completed",
};

export { CONNECT, STRAPI, SERVICE_PROVIDERS_ALL, GTM_CODE, API, APP };
