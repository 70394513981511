import { API } from "../utils/definitions";
import auth from "./auth";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../utils/localStorage";
import vilalovings from "./vilalovings";

class Experiences {
  createExperience(data) {
    return auth
      .requestWithBearer("post", `${API.EXPERIENCES}`, data)
      .then(async (experienceData) => {
        removeLocalStorage("vilaloving");
        const currentVilaloving = await vilalovings.getCurrentVilaloving();
        if (currentVilaloving) {
          setLocalStorage("vilaloving", currentVilaloving);
        }
        setLocalStorage("experience", experienceData);
        return Promise.resolve(experienceData);
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  getCurrentExperience() {
    const userData = auth.getStrapiUserStorage();
    if (!userData) return null;

    const currentExperience = getLocalStorage("experience");

    if (currentExperience && currentExperience.status === "enabled") {
      return currentExperience;
    }

    const url = `${API.EXPERIENCES}/?status=enabled&idUser=${userData.id}`;

    return auth
      .requestWithBearer("get", url)
      .then((experienceData) => {
        if (
          experienceData &&
          Array.isArray(experienceData) &&
          experienceData.length > 0
        ) {
          experienceData = experienceData[0];
          return Promise.resolve(experienceData);
        }
        return null;
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  updateExperience(data) {
    return auth
      .requestWithBearer("put", `${API.EXPERIENCES}/${data.id}`, data)
      .then(async (experienceData) => {
        removeLocalStorage("vilaloving");
        const currentVilaloving = await vilalovings.getCurrentVilaloving();
        if (currentVilaloving) {
          setLocalStorage("vilaloving", currentVilaloving);
        }
        setLocalStorage("experience", experienceData);
        return Promise.resolve(experienceData);
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  transformExperienceToParticipant(currentExperience) {
    const participantData = {
      images: currentExperience.gallery,
      description: currentExperience.description,
      marker: {
        latitude: currentExperience.latitude * 1,
        longitude: currentExperience.longitude * 1,
        name: currentExperience.name,
      },
      latitude: currentExperience.latitude,
      longitude: currentExperience.longitude,
      name: currentExperience.name,
      address: currentExperience.address,
      category: currentExperience.category,
      discount: currentExperience.discount,
      status: currentExperience.status,
      idProvider: currentExperience.idProvider,
    };
    return participantData;
  }
}

export default new Experiences();
