import React from "react";
import IcomoonReact, { iconList } from "icomoon-react";
import iconSet from "./selection.json";
import * as FeatherIcons from "react-icons/fi";
import * as Ionicons from "react-icons/io";
import * as BootstrapIcons from "react-icons/bs";

function checkIfExistsOnIcomoon(icon) {
  if (!icon || typeof icon !== "string") return false;
  const list = iconList(iconSet);
  if (!list || !Array.isArray(list)) return false;

  return list.includes(icon);
}

const Icon = (props) => {
  const { size, icon, className, onClick, color, parentClass, type } = props;

  let iconElement = null;
  const isIcomoon = checkIfExistsOnIcomoon(icon);

  if (isIcomoon) {
    iconElement = (
      <span onClick={onClick ? onClick : null} className={parentClass}>
        <IcomoonReact
          className={className}
          color={color ? color : null}
          iconSet={iconSet}
          size={size}
          icon={icon}
        />
      </span>
    );
  } else {
    let iconType = {};

    switch (type) {
      case "ionicons":
        iconType = Ionicons;
        break;
      case "bootstrap":
        iconType = BootstrapIcons;
        break;
      default:
        iconType = FeatherIcons;
        break;
    }

    const Icon = iconType[icon];
    if (Icon)
      iconElement = <Icon size={size} color={color} className={className} />;
  }

  if (onClick) {
    return <span onClick={onClick}>{iconElement}</span>;
  }

  return iconElement;
};

Icon.defaultProps = {
  size: "32px",
  className: "icon",
  parentClass: "wrapIcon",
};

export default Icon;
