import React from "react";

import Icon from "../../icon";
import Modal from "../modal";
import Countdown from "./countdown";
import EventBus from "../../../utils/eventBus";

import styles from "./styles.module.scss";
import {
  COUNTDOWN_TIME_CHANGE,
  CLEAR_HEADER,
  DISCOUNT_CHANGE,
  COUNTDOWN_TIME_FINISHED,
} from "../../../utils/events";
import ClockPercentage from "./clockpercentage";
import vilalovings from "../../../helpers/vilalovings";
import {
  clearLocalStorage,
  getLocalStorage,
} from "../../../utils/localStorage";
import PushHistory from "../../../utils/pushHistory";
import localeAsString from "../../../utils/localeAsString";
import { APP } from "../../../utils/definitions";

export default class VilalovingHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: getLocalStorage("userData"),
      logged: this.props.logged,
      timeRemaining: false,
      showPrivacyPolicy: false,
      discount: 5,
      expired_at: null,
      created_at: null,
      userMenu: false,
    };
  }

  async componentDidMount() {
    // evento que recogerá la fecha del temporizador y la seteará en el state para el componente countdown.
    EventBus.$on(COUNTDOWN_TIME_CHANGE, this.setDateTimes);

    // evento que recogerá el descuento que se mostrará
    EventBus.$on(DISCOUNT_CHANGE, this.setDiscount);

    // evento que borrará el descuento y tiempo restante del vilaloving.
    EventBus.$on(CLEAR_HEADER, this.removeVilalovingData);

    // checkear en localstorage si hay un vilaloving, para recuperar el tiempo que queda de vilaloving
    const currentVilaloving = await vilalovings.getCurrentVilalovingHeaderData();
    if (Object.keys(currentVilaloving).length > 0)
      this.setState(currentVilaloving);
  }

  componentWillUnmount() {
    EventBus.$off(COUNTDOWN_TIME_CHANGE);
    EventBus.$off(DISCOUNT_CHANGE);
    EventBus.$off(CLEAR_HEADER);
  }

  removeVilalovingData = (ev) => {
    const { discount } = ev.detail;
    if (!discount) return;
    const newDiscount = discount === "reset" ? null : 5;
    this.setState({
      discount: newDiscount,
      expired_at: null,
      created_at: null,
    });
  };

  setDateTimes = (ev) => {
    const { expired_at, created_at } = ev.detail;
    if (!expired_at || !created_at) return;

    this.setState({ expired_at, created_at }, () => {
      if (this.props.toggleClock) this.toggleTimeRemaining();
    });
  };

  setDiscount = (ev) => {
    const { discount } = ev.detail;
    if (!discount) return;

    this.setState({ discount });
  };

  parseDiscount(discount) {
    return `-${discount}%`;
  }

  onCountDownFinished = () => {
    this.setState({ expired: true }, EventBus.$emit(COUNTDOWN_TIME_FINISHED));
  };

  toggleTimeRemaining = () => {
    if (!this.state.timeRemaining)
      this.setState({ timeRemaining: true }, () => {
        setTimeout(() => {
          this.setState({ timeRemaining: false });
        }, 2000);
      });
  };

  toggleUserMenu = () => {
    if (!this.state.userMenu)
      this.setState({ userMenu: true }, () => {
        setTimeout(() => {
          this.setState({ userMenu: false });
        }, 3000);
      });
  };

  showPrivacyPolicy = () =>
    this.setState({ showPrivacyPolicy: !this.state.showPrivacyPolicy });

  logout() {
    clearLocalStorage();
    this.navigateToHome();
  }

  navigateToHome = () => PushHistory(APP.HOME);

  render() {
    const { state, toggleTimeRemaining } = this;
    const {
      userData,
      timeRemaining,
      logged,
      showPrivacyPolicy,
      expired,
      expired_at,
      created_at,
      discount,
      userMenu,
    } = state;
    return (
      <div className={styles.header}>
        {logged && (
          <div className={styles.discount}>
            {discount !== null ? (
              <div className={styles.percentage}>
                {this.parseDiscount(discount)}
              </div>
            ) : null}
            <div className={styles.clockWrapper} onClick={toggleTimeRemaining}>
              {expired_at !== null && created_at !== null ? (
                <ClockPercentage
                  expiredDate={expired_at}
                  createdDate={created_at}
                />
              ) : null}
              <div
                className={`${styles.timeRemainingWrapper} ${
                  timeRemaining ? "" : styles.closed
                }`}
              >
                <div className={styles.timeRemaining}>
                  {!expired ? (
                    <>
                      <div>
                        {localeAsString("master_vilaloving_header_expire_in")}:
                      </div>
                      {expired_at !== null && (
                        <Countdown
                          countDownDate={expired_at}
                          onCountdownFinished={this.onCountDownFinished}
                        />
                      )}
                    </>
                  ) : (
                    <div>
                      {localeAsString("master_vilaloving_header_caducated")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={showPrivacyPolicy}
          closeFn={this.showPrivacyPolicy}
          className="scroll"
        >
          <span className={styles.privacyPolicy}>
            <div>Lorem ipsum dolor</div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est nec
              volutpat ut in velit habitant. Malesuada auctor mauris, nisi vitae
              bibendum. A, auctor viverra fermentum vulputate. Volutpat, integer
              eu at duis mauris integer sed eget aliquet. Vestibulum, feugiat
              ante facilisi amet velit platea nunc nunc mi. Vestibulum amet sed
              amet ultrices id a, ornare purus magna.
            </div>
            <div>Lorem ipsum dolor</div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est nec
              volutpat ut in velit habitant. Malesuada auctor mauris, nisi vitae
              bibendum. A, auctor viverra fermentum vulputate. Volutpat, integer
              eu at duis mauris integer sed eget aliquet. Vestibulum, feugiat
              ante facilisi amet velit platea nunc nunc mi. Vestibulum amet sed
              amet ultrices id a, ornare purus magna.
            </div>
            <div>Lorem ipsum dolor</div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est nec
              volutpat ut in velit habitant. Malesuada auctor mauris, nisi vitae
              bibendum. A, auctor viverra fermentum vulputate. Volutpat, integer
              eu at duis mauris integer sed eget aliquet. Vestibulum, feugiat
              ante facilisi amet velit platea nunc nunc mi. Vestibulum amet sed
              amet ultrices id a, ornare purus magna.
            </div>
          </span>
        </Modal>

        <div className={styles.vilalovingLogo}>
          <Icon icon="VilalovingLogo_White" />
        </div>

        {logged && (
          <div className={styles.user}>
            <div
              className={styles.faq}
              onClick={() => this.showPrivacyPolicy()}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.09003 7.99999C8.57546 6.62004 9.98541 5.7914 11.4272 6.0387C12.869 6.28601 13.9222 7.53714 13.92 8.99999C13.92 11 10.92 12 10.92 12"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="11" cy="16" r="1" fill="white" />
              </svg>
            </div>
            <div
              className={`${styles.profile} ${userMenu ? styles.open : ""}`}
              onClick={() => this.toggleUserMenu()}
            >
              <Icon icon="icon_profile_girl" color={"white"} />
              <div className={`${styles.menu} ${userMenu ? styles.open : ""}`}>
                <div className={styles.session}>
                  {localeAsString("master_vilaloving_header_hi")},{" "}
                  {`${userData.firstName} ${userData.lastName}`}
                </div>
                <div className={styles.logout} onClick={() => this.logout()}>
                  {localeAsString("master_vilaloving_header_log_out")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
