import React from "react";

import Icon from "../../icon";
import localeAsString from "../../../utils/localeAsString";

import styles from "./styles.module.scss";

export default class Roulette extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyId: 0,
      animation: false,
      experience: null,
      icon: <Icon icon="ShoppingIcon" />,
    };
  }

  returnAnimationState = () => {
    return this.state.animation;
  };

  randomIcon = () => {
    for (let index = 1; index < 20; index++)
      this.generateRandomIcon(150 * index);
    this.generateRandomIcon(3000, this.state.experience);
    this.generateRandomIcon(3250, "blink");
    this.generateRandomIcon(3500, this.state.experience);
    this.generateRandomIcon(3750, "blink");
    this.generateRandomIcon(4000, this.state.experience);
    this.generateRandomIcon(4250, "blink");
    this.generateRandomIcon(4500, this.state.experience);
  };

  generateRandomIcon = (timeout, fake) => {
    const icons = [
      <Icon icon="ShoppingIcon" />,
      <Icon icon="GastronomyIcon" />,
      <Icon icon="ActivitiesIcon" />,
    ];

    setTimeout(() => {
      let icon;
      if (fake) {
        if (fake === "shopping") icon = icons[0];
        else if (fake === "gastronomy") icon = icons[1];
        else if (fake === "activities") icon = icons[2];
      } else {
        do {
          icon = icons[Math.floor(Math.random() * icons.length)];
        } while (icon.props.icon === this.state.icon.props.icon);
      }
      this.setState({ icon });
    }, timeout);
  };

  getDegrees = (experience) => {
    switch (experience) {
      case "gastronomy":
        return 3660;
      case "activities":
        return 3780;
      case "shopping":
      default:
        return 3900;
    }
  };

  randomExperience = () => {
    if (this.state.animation) return;
    let experiences = [];
    Object.keys(this.props.experiences).forEach((key) => {
      if (this.props.experiences[key].available) experiences.push(key);
    });
    const experience =
      experiences[Math.floor(Math.random() * experiences.length)];
    let value = 0;
    value = this.getDegrees(experience);

    this.setState({ animation: value, experience }, () => {
      this.randomIcon();
      setTimeout(() => {
        this.setState({ animation: false });
      }, 5000);
      if (this.props.callback) this.props.callback();
    });
  };

  randomExperienceAgain = () => {
    if (this.state.animation) return;
    const keyId = this.state.keyId + 1;
    this.setState({ keyId }, () => {
      setTimeout(() => {
        this.randomExperience();
      }, 100);
    });
  };

  // clientX
  handleOnTouchStart = (touch) => {
    const { clientX, clientY } = touch;
    this.setState({ clientX, clientY });
  };

  handleOnTouchEnd = (touch) => {
    const { clientX, clientY } = this.state;
    if (clientX > touch.clientX && clientX - touch.clientX > 80)
      this.randomExperienceAgain();
    else if (clientY < touch.clientY && touch.clientY - clientY > 80)
      this.randomExperienceAgain();
  };

  render() {
    const { experiences } = this.props;
    const { animation, icon, keyId } = this.state;
    let textPath = `
        <textPath xlink:href="#shopping" text-anchor="middle" startOffset="48%" style={{fill: 'white', fontFamily: 'Kapra Neue', fontWeight: '500', fontSize: '32px', lineHeight: '24px', letterSpacing: '2px', textTransform: 'uppercase'}}>${localeAsString(
          "master_vilaloving_roulette_shopping"
        ).toUpperCase()}</textPath>
        <textPath xlink:href="#activities" text-anchor="middle" startOffset="50%" style={{fill: 'white', fontFamily: 'Kapra Neue', fontWeight: '500', fontSize: '32px', lineHeight: '24px', letterSpacing: '2px', textTransform: 'uppercase'}}>${localeAsString(
          "master_vilaloving_roulette_activities"
        ).toUpperCase()}</textPath>
        <textPath xlink:href="#gastronomy" text-anchor="middle" startOffset="50%" style={{fill: 'white', fontFamily: 'Kapra Neue', fontWeight: '500', fontSize: '32px', lineHeight: '24px', letterSpacing: '2px', textTransform: 'uppercase'}}>${localeAsString(
          "master_vilaloving_roulette_gastronomy"
        ).toUpperCase()}</textPath>
        `;
    if (!experiences) return null;

    let rouletteColors = `conic-gradient(
            ${experiences.shopping.available ? "#1F71B4" : "#BCBCC0"} 33.3%,
            ${
              experiences.activities.available ? "#3DA897" : "#BCBCC0"
            } 0 66.6%, 
            ${experiences.gastronomy.available ? "#D8004D" : "#BCBCC0"} 0 100%
        )`;
    return (
      <div
        className={styles.wrapper}
        key={`roulette_${keyId}`}
        onTouchStart={(e) => {
          this.handleOnTouchStart(e.changedTouches[0]);
        }}
        onTouchEnd={(e) => {
          this.handleOnTouchEnd(e.changedTouches[0]);
        }}
      >
        <div className={styles.scale}>
          <div className={styles.rouletteArrow}>
            <Icon icon="RouletteArrow" />
          </div>
          <div
            className={`${styles.roulette}`}
            style={{
              background: rouletteColors,
              transform: `rotate(${animation}deg)`,
            }}
          >
            <img alt="empty-template" useMap="#roulette" />
            <map name="roulette">
              <area
                alt="shopping-area"
                shape="poly"
                coords="150,150, 280,220, 300,110, 225,25, 220,15, 150,0"
                onClick={() =>
                  !animation &&
                  experiences.shopping.available &&
                  experiences.shopping.action()
                }
              />
              <area
                alt="gastronomy-area"
                shape="poly"
                coords="150,150, 20,220, 10,110, 75,25, 150,5"
                onClick={() =>
                  !animation &&
                  experiences.gastronomy.available &&
                  experiences.gastronomy.action()
                }
              />
              <area
                alt="activities-area"
                shape="poly"
                coords="150,150, 25,225, 75,275, 150,300, 225,275, 275,225"
                onClick={() =>
                  !animation &&
                  experiences.activities.available &&
                  experiences.activities.action()
                }
              />
            </map>
            <div className={`${styles.line} ${styles.line_1}`} />
            <div className={`${styles.line} ${styles.line_2}`} />
            <div className={`${styles.line} ${styles.line_3}`} />
            <div
              className={styles.circleLogo}
              style={{ transform: `rotate(-${animation}deg)` }}
            >
              {icon}
            </div>
            <div className={styles.circleText}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="300"
                height="300"
                version="1.1"
              >
                <defs>
                  <path
                    d="M0 95 S0 0 95 0 S190 95 190 95"
                    id="shopping"
                    transform="translate(58,58) rotate(60.3 95 95)"
                  />
                  <path
                    d="M0 95 S0 0 95 0 S190 95 190 95"
                    id="activities"
                    transform="translate(58,58) rotate(180.6 95 95)"
                  />
                  <path
                    d="M0 95 S0 0 95 0 S190 95 190 95"
                    id="gastronomy"
                    transform="translate(58,58) rotate(300.9 95 95)"
                  />
                </defs>
                <path
                  d="M0 95 S0 0 95 0 S190 95 190 95"
                  style={{ fill: "rgba(0,0,0,0)" }}
                  transform="translate(58,58) rotate(60.3 95 95)"
                />
                <path
                  d="M0 95 S0 0 95 0 S190 95 190 95"
                  style={{ fill: "rgba(0,0,0,0)" }}
                  transform="translate(58,58) rotate(180.6 95 95)"
                />
                <path
                  d="M0 95 S0 0 95 0 S190 95 190 95"
                  style={{ fill: "rgba(0,0,0,0)" }}
                  transform="translate(58,58) rotate(300.9 95 95)"
                />

                <text
                  style={{
                    fill: "white",
                    fontFamily: "Kapra Neue",
                    fontWeight: "500",
                    fontSize: "32px",
                    lineHeight: "24px",
                    letterSpacing: "2px",
                  }}
                  dangerouslySetInnerHTML={{ __html: textPath }}
                  className={styles.shopping}
                />
              </svg>
            </div>
          </div>
          <div className={styles.rouletteCircleArrow}>
            <svg
              width="203"
              height="38"
              viewBox="0 0 203 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.8905 2.61621C29.5076 23.6121 63.8234 36.0454 101.195 36.0454C138.801 36.0454 173.469 23.3775 201.086 2.14703"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.18322 10.5922L1.8905 2.61599L9.99936 1.79492"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
