const getLanguageCode = (code) => {
  let language = "en_US";

  switch (code) {
    case 0:
      language = "es_ES";
      break;
    case 1:
      language = "ca_ES";
      break;
    case 2:
      language = "en_US";
      break;
    case 3:
      language = "fr_FR";
      break;
    case 4:
      language = "de_DE";
      break;
    case 5:
      language = "it_IT";
      break;
    case 6:
      language = "ru_RU";
      break;
    case 7:
      language = "pt_BR";
      break;
    case 8:
      language = "oc_ES";
      break;
    default:
      language = "en_US";
      break;
  }

  return language;
};

export default getLanguageCode;
