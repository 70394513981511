import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Seo({ lang, children }) {
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={"%s - " || ""}
        htmlAttributes={{
          lang,
        }}
        title={"Spotlio Webapp"}
      >
        {children}
      </Helmet>
    </HelmetProvider>
  );
}

export default Seo;
