import React from "react";
import styles from "./styles.module.scss";

export default class SumupValue extends React.Component {
  render() {
    const { title, value } = this.props;
    return (
      <div className={styles.box}>
        <div className={styles.title}>{title}</div>
        <div className={styles.value}>{value}</div>
      </div>
    );
  }
}
