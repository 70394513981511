import React from "react";
import PushHistory from "../../../utils/pushHistory";

import Roulette from "../../../components/vilaloving/roulette";
import Layout from "../../../components/vilaloving/layout";
import Icon from "../../../components/icon";

import styles from "./styles.module.scss";
import AlertDialog from "../../../components/dialog";
import vilalovings from "../../../helpers/vilalovings";
import localeAsString from "../../../utils/localeAsString";
import { APP } from "../../../utils/definitions";
import experiences from "../../../helpers/experiences";
import { removeLocalStorage } from "../../../utils/localStorage";
import serviceprovider from "../../../helpers/serviceprovider";
import EventBus from "../../../utils/eventBus";
import { CLEAR_HEADER } from "../../../utils/events";
import { navigateToCurrentStatus } from "../../../helpers/currentStatus";

export default class VilalovingRoulette extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showExpiredDialog: false,
      enabled: true,
      again: true,
      nextscreen: false,
    };

    this.roulette = React.createRef();
  }

  async componentDidMount() {
    const { history } = this.props;
    if (
      !history.location.state ||
      !history.location.state.redirect ||
      !history.location.state.expired
    ) {
      await navigateToCurrentStatus();
    }

    // Si el vilaloving actual está caducado, enseñamos el dialogo.
    if (history.location.state && history.location.state.expired) {
      const currentVilaloving = await vilalovings.getCurrentVilaloving();
      // solo lo enseñamos si hay algún vilaloving activo
      if (currentVilaloving) {
        this.showExpiredDialog();
      }
    }

    serviceprovider.getAll();

    const disabledCategories = await vilalovings.vilalovingCompletedExperiences();
    const experienceCategory = {
      shopping: {
        available: !disabledCategories.includes("shopping"),
        action: () => this.navigateToParticipants("shopping"),
      },
      activities: {
        available: !disabledCategories.includes("activities"),
        action: () => this.navigateToParticipants("activities"),
      },
      gastronomy: {
        available: !disabledCategories.includes("gastronomy"),
        action: () => this.navigateToParticipants("gastronomy"),
      },
    };
    this.setState({ experienceCategory });
  }

  navigateToParticipants = (option) =>
    PushHistory(`${APP.PARTICIPANTS}/${option}`, false, { redirect: true });

  showExpiredDialog = (ev = {}, reason = null) => {
    if (reason && reason === "backdropClick") return;
    this.setState({ showExpiredDialog: !this.state.showExpiredDialog });
  };

  // resetear el vilaloving del usuario (cancelar la experiencia actual junto a su vilaloving)
  resetVilaloving = async () => {
    let state = {
      showExpiredDialog: false,
    };

    try {
      let currentExperience = await experiences.getCurrentExperience();
      if (currentExperience) {
        currentExperience.status = "expired";
        await experiences.updateExperience(currentExperience);
        removeLocalStorage("experience");
      }

      let currentVilaloving = await vilalovings.getCurrentVilaloving();
      if (currentVilaloving) {
        currentVilaloving.status = "expired";
        await vilalovings.updateVilaloving(currentVilaloving);
        removeLocalStorage("vilaloving");
        EventBus.$emit(CLEAR_HEADER, { discount: "reset" });
      }

      const disabledCategories = await vilalovings.vilalovingCompletedExperiences();
      state.experienceCategory = {
        shopping: {
          available: !disabledCategories.includes("shopping"),
          action: () => this.navigateToParticipants("shopping"),
        },
        activities: {
          available: !disabledCategories.includes("activities"),
          action: () => this.navigateToParticipants("activities"),
        },
        gastronomy: {
          available: !disabledCategories.includes("gastronomy"),
          action: () => this.navigateToParticipants("gastronomy"),
        },
      };
    } catch (error) {}

    this.setState(state);
  };

  setStateEnabled = () => {
    this.setState({ enabled: false });
    setTimeout(() => {
      this.setState({ nextscreen: true });
    }, 5000);
  };

  nextScreen = () => {
    const experience = this.roulette.current.state.experience;
    this.state.experienceCategory[experience].action();
  };

  restartAnimation = () => {
    this.setState({ again: false }, () => {
      this.roulette.current.randomExperienceAgain();
      setTimeout(() => {
        this.setState({ again: true });
      }, 5000);
    });
  };

  render() {
    const {
      showExpiredDialog,
      experienceCategory,
      enabled,
      nextscreen,
      again,
    } = this.state;
    return (
      <Layout backgroundColor={"#1F3084"} logged>
        <div className={styles.rouletteWrapper}>
          <div className={styles.rouletteDesc}>
            {localeAsString("master_vilaloving_roulette_description")}
          </div>
          {!experienceCategory ? (
            <div className={styles.roulettePlaceholder} />
          ) : (
            <Roulette
              experiences={experienceCategory}
              ref={this.roulette}
              callback={this.setStateEnabled}
            />
          )}
          {showExpiredDialog && (
            <AlertDialog
              open={showExpiredDialog}
              close={this.showExpiredDialog}
              title={localeAsString(
                "master_vilaloving_roulette_expired_experience"
              )}
              buttonText={localeAsString(
                "master_vilaloving_roulette_restart_experience"
              )}
              onClick={this.resetVilaloving}
            />
          )}
        </div>
        <div className={styles.bottomCTA}>
          <div className={styles.bottomCTAWrapper}>
            {!nextscreen ? (
              <div
                className={`${styles.button} ${enabled ? "" : styles.disabled}`}
                onClick={() => {
                  if (enabled) this.roulette.current.randomExperience();
                }}
              >
                <Icon icon="rotate-cw" />{" "}
                {localeAsString("master_vilaloving_roulette_random")}
              </div>
            ) : (
              <>
                <div
                  className={styles.again}
                  onClick={() => {
                    if (again) this.restartAnimation();
                  }}
                >
                  <Icon icon="rotate-cw" />
                </div>
                <div
                  className={`${styles.continue} ${
                    again ? "" : styles.disabled
                  }`}
                  onClick={() => {
                    if (again) this.nextScreen();
                  }}
                >
                  {localeAsString("master_vilaloving_roulette_continue")}
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}
