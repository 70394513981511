import React from "react";
import Modal from "@material-ui/core/Modal";

import Icon from "../icon";

import "./index.scss";

const modal = ({ open, children, closeFn, big, orderHistory }) => {
  return (
    <Modal
      open={open}
      disablePortal={true}
      onBackdropClick={closeFn}
      onEscapeKeyDown={closeFn}
    >
      <div
        className={
          orderHistory ? "oldModal" : "content " + (big ? "bigModal" : "")
        }
      >
        {closeFn && (
          <Icon
            className={"closeModal"}
            onClick={closeFn}
            icon="feather-x-square"
          />
        )}
        {children}
      </div>
    </Modal>
  );
};

export default modal;
