import React from "react";
import { Container } from "@material-ui/core";

import Header from "../header";

import styles from "./styles.module.scss";
import { COUNTDOWN_TIME_FINISHED } from "../../../utils/events";
import PushHistory from "../../../utils/pushHistory";
import { APP } from "../../../utils/definitions";
import EventBus from "../../../utils/eventBus";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
  window.document.addEventListener(
    "touchmove",
    (e) => {
      if (e.scale !== 1) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
}

export default class VilalovingLayout extends React.Component {
  componentDidMount() {
    EventBus.$on(COUNTDOWN_TIME_FINISHED, this.handleExpiration);
  }

  componentWillUnmount() {
    EventBus.$off(COUNTDOWN_TIME_FINISHED);
  }

  handleExpiration() {
    PushHistory(APP.ROULETTE, false, { expired: true });
  }

  render() {
    const { logged, backgroundColor, children, toggleClock } = this.props;
    return (
      <div style={{ backgroundColor: backgroundColor }} className={styles.root}>
        <Container maxWidth="lg">
          <Header
            logged={logged ? logged : false}
            toggleClock={toggleClock ? toggleClock : false}
          />
          {children}
        </Container>
      </div>
    );
  }
}
