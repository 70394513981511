import React from "react";
import PushHistory from "../../../utils/pushHistory";

import Layout from "../../../components/vilaloving/layout";
import Input from "../../../components/input";
import Loading from "../../../components/loadingAnimation";

import styles from "./styles.module.scss";
import auth from "../../../helpers/auth";
//import QS from "../../../utils/queryString";
import localeAsString from "../../../utils/localeAsString";
import { APP } from "../../../utils/definitions";
import { navigateToCurrentStatus } from "../../../helpers/currentStatus";
// import { clearLocalStorage } from "../../../utils/localStorage";

export default class VilalovingHome extends React.Component {
  state = {
    form: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validForm: {
      validFirstName: false,
      validLastName: false,
      validEmail: false,
      validPassword: false,
    },
    error: null,
    focusedInput: null,
    submit: false,
  };

  async componentDidMount() {
    await navigateToCurrentStatus();
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prev) => ({
      ...prev,
      form: {
        ...this.state.form,
        [name]: value,
      },
    }));
  };

  handleSubmit = async () => {
    await this.setState({ submit: true });
    const isFormValid = await this.isFormValid();
    if (!isFormValid) return this.setState({ submit: false });
    const { form } = this.state;
    const user_id = auth.queryParamUserId;
    const data = {
      ...form,
      username: form.email,
      status: "enabled",
      idSpotlio: user_id + "" || null,
    };
    try {
      const userData = await auth.strapiRegister(data);
      if (userData) {
        auth.unsetQueryParamUser();
        return this.navigateToRoulette();
      }
    } catch (error) {
      this.setState(this.getErrorState(error));
    }
  };

  isFormValid = async () => {
    let isValid = true;
    await this.validateFirstName();
    await this.validateLastName();
    await this.validateEmail();
    await this.validatePassword();
    const { validForm } = this.state;
    Object.keys(validForm).forEach((label) => {
      if (validForm[label]) isValid = false;
    });
    return isValid;
  };

  getErrorState = (error = false) => {
    return {
      ...this.state,
      error: error,
      submit: false,
    };
  };

  navigateToLogin = () => PushHistory(APP.LOGIN);

  navigateToRoulette = () =>
    PushHistory(APP.ROULETTE, false, { redirect: true });

  validateFirstName = async () => {
    const { form, validForm } = this.state;
    let validations = { ...validForm };
    if (form.firstName) {
      validations.validFirstName = false;
    } else {
      validations.validFirstName = true;
    }
    await this.setState({ validForm: validations });
  };

  validateLastName = async () => {
    const { form, validForm } = this.state;
    let validations = { ...validForm };
    if (form.lastName) {
      validations.validLastName = false;
    } else {
      validations.validLastName = true;
    }
    await this.setState({ validForm: validations });
  };

  validateEmail = async () => {
    const { form, validForm } = this.state;
    let validations = { ...validForm };
    let isValid = false;

    if (!form.email) isValid = true;
    if (typeof form.email !== "string") isValid = true;
    if (form.email === 0) isValid = true;
    if (
      //eslint-disable-next-line
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        form.email
      )
    ) {
      isValid = true;
    }

    validations.validEmail = isValid;
    await this.setState({ validForm: validations });
  };

  validatePassword = async () => {
    const { form, validForm } = this.state;
    let validations = { ...validForm };
    if (!form.password || form.password.length < 6) {
      validations.validPassword = true;
    } else {
      validations.validPassword = false;
    }
    await this.setState({ validForm: validations });
  };

  isButtonReadyToBePressed = (form, submit) => {
    let isReady = true;
    if (submit) return false;
    Object.keys(form).forEach((element) => {
      if (form[element] === "") isReady = false;
    });
    return isReady;
  };

  render() {
    const { form, validForm, error, focusedInput, submit } = this.state;
    return (
      <Layout backgroundColor={"#1F3084"}>
        <div className={styles.formWrapper}>
          <div className={styles.formDescription}>
            {localeAsString("master_vilaloving_register_create_your_account")}
          </div>
          <div className={styles.formInputs}>
            <div className={styles.input}>
              <Input
                label={`${localeAsString("master_vilaloving_form_name")}*`}
                value={form.firstName}
                type="text"
                name="firstName"
                onChange={this.handleInputChange}
                error={validForm.validFirstName}
                helperText={localeAsString(
                  "master_vilaloving_form_required_name"
                )}
                onBlur={() => this.validateFirstName()}
                autoFocus={focusedInput === 0}
                onClick={() => this.setState({ focusedInput: 0 })}
                enterPressed={() => this.setState({ focusedInput: 1 })}
              />
            </div>
            <div className={styles.input}>
              <Input
                label={`${localeAsString("master_vilaloving_form_lastname")}*`}
                value={form.lastName}
                type="text"
                name="lastName"
                onChange={this.handleInputChange}
                error={validForm.validLastName}
                helperText={localeAsString(
                  "master_vilaloving_form_required_lastname"
                )}
                onBlur={() => this.validateLastName()}
                autoFocus={focusedInput === 1}
                onClick={() => this.setState({ focusedInput: 1 })}
                enterPressed={() => this.setState({ focusedInput: 2 })}
              />
            </div>
            <div className={styles.input}>
              <Input
                label={`${localeAsString("master_vilaloving_form_email")}`}
                value={form.email}
                type="email"
                name="email"
                placeholder="example@example.com"
                onChange={this.handleInputChange}
                error={validForm.validEmail}
                helperText={localeAsString(
                  "master_vilaloving_form_required_email"
                )}
                onBlur={() => this.validateEmail()}
                autoFocus={focusedInput === 2}
                onClick={() => this.setState({ focusedInput: 2 })}
                enterPressed={() => this.setState({ focusedInput: 3 })}
              />
            </div>
            <div className={styles.input}>
              <Input
                label={`${localeAsString("master_vilaloving_form_password")}*`}
                placeholder={localeAsString(
                  "master_vilaloving_form_password_placeholder"
                )}
                type="password"
                name="password"
                value={form.password}
                onChange={this.handleInputChange}
                error={validForm.validPassword}
                helperText={localeAsString(
                  "master_vilaloving_form_required_password"
                )}
                onBlur={() => this.validatePassword()}
                autoFocus={focusedInput === 3}
                onClick={() => this.setState({ focusedInput: 3 })}
                enterPressed={() => this.setState({ focusedInput: 4 })}
              />
            </div>
            {error &&
              (error === "Missing required field" ? (
                <div className={styles.error}>
                  {localeAsString(
                    "master_vilaloving_form_missing_required_field"
                  )}
                </div>
              ) : (
                <div className={styles.error}>
                  {localeAsString(
                    "master_vilaloving_form_user_already_registered"
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.bottomCTA}>
          <div className={styles.registered} onClick={this.navigateToLogin}>
            {localeAsString("master_vilaloving_register_already_one")}&nbsp;
            <b>{localeAsString("master_vilaloving_register_log_in")}</b>
          </div>
          <div className={styles.bottomCTAWrapper}>
            <div
              className={
                this.isButtonReadyToBePressed(form, submit)
                  ? `${styles.button}`
                  : `${styles.button} ${styles.disabled}`
              }
              onClick={this.handleSubmit}
            >
              {submit ? (
                <Loading />
              ) : (
                localeAsString("master_vilaloving_register_sign_up")
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
