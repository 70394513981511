import React from "react";

export default class Countdown extends React.Component {
  state = {
    remainingTime: null,
    error: false,
  };

  stopped = false;

  componentDidMount() {
    const { countDownDate } = this.props;
    if (!countDownDate) return;

    this.updateCountdown();
  }

  componentWillUnmount() {
    this.stopped = true;
  }

  updateCountdown = () => {
    if (this.stopped || this.state.error) return;

    const { countDownDate } = this.props;

    // Get today's date and time
    let now = new Date().getTime();

    // Find the distance between now and the count down date
    let distance = countDownDate - now;

    // Time calculations for hours, minutes and seconds
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    // remaining time
    const remainingTime = `${hours}:${minutes}:${seconds}`;

    // If the count down is finished we sent notification
    if (distance < 0) {
      this.stopped = true;
      console.log("EXPIRED");
      if (this.props.onCountdownFinished) this.props.onCountdownFinished();
      return;
    }

    // Update the count down every second
    this.setState({ remainingTime }, () =>
      setTimeout(this.updateCountdown, 1000)
    );
  };

  render() {
    const { className } = this.props;
    const { remainingTime, error } = this.state;

    if (error || !remainingTime) return null;

    return <span className={className}>{remainingTime}</span>;
  }
}
