import React from "react";

import ca from "./locales/ca";

function createMarkup(text) {
  return { __html: text };
}

//eslint-disable-next-line
export default (id, isHtml = false) => {
  let locale = null;
  let language = "ca";
  //let language = getLocalStorage("language");
  //if (!language) language = "fre";
  switch (language) {
    case "ca":
    default:
      locale = ca;
      break;
  }

  if (locale[id]) {
    if (isHtml === true)
      return <p dangerouslySetInnerHTML={createMarkup(locale[id])}></p>;
    return locale[id];
  } else {
    if (process.env.NODE_ENV === "development")
      console.error(
        `[SPOTLIO Intl] Could not find key "${id}". Returining ID instead.`
      );
    return id;
  }
};
