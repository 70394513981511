import React from "react";
import styles from "./bookingWidget.module.scss";
import Requester from "../../helpers/requester";

const availableResorts = [
  "visittelluride",
  "cranmore",
  "steamboat",
  "stayaspensnowmass",
  "whistlerblackcomb",
  "mountsnowb2c",
  "purgatoryresort",
  "skimax",
  "steamboatgrand",
  "lutsen",
  "eagleridgeatlutsen",
  "skigranitepeak",
  "vail",
  "parkcitymountain",
  "kirkwood",
  "skiheavenly",
  "beavercreek",
  "breckenridge",
  "keystone",
  "northstar",
  "whistlerheliski",
  "steamboatgrandgroups",
  "spotlio",
  "getskitickets",
  "wvstateparks",
  "huntermtnbus",
  "sunshinevillage",
  ,
  "lakelouis",
  "b2b",
  "skiwhitefish",
  "durangosilvertonrailroad",
  "caribouhighlandsatlutsen",
  "lutsenresort",
  "bluefinatlutsen",
  "mountaininnatlutsen",
  "americinntofteatlutsen",
  "cascadelodgeatlutsen",
  "superiorridgeatlutsen",
  "solbakkenatlutsen",
  "eastbayatlutsen",
  "thomsonitebeachatlutsen",
  "bestwesternatlutsen",
  "gmhotel",
  "winterlutsen",
  "locallutsen",
  "ownerlutsen",
  "residentlutsen",
  "canadianlutsen",
  "bulkuploader",
  "telluride",
  "summerlutsen",
  "papacharlieslutsen",
  "lutsen_landings",
  "summerskibig3",
  "bookingskibig3",
  "packagelutsen",
  "skibig3b2b",
  "whistlerblackcombgroup",
  "dktxp",
  "superiornational",
  "killingtonfifto",
  "killingtonelodge",
  "picomountain",
  "killington",
  "redriverskiarea",
  "gostowe",
  "stowe",
  "lodgingovations",
  "beavercreekgroups",
  "breckenridgegroups",
  "crestedbuttegroups",
  "heavenlygroups",
  "huntergroups",
  "keystonegroups",
  "kirkwoodgroups",
  "libertygroups",
  "mountsnowgroups",
  "northstargroups",
  "okemogroups",
  "parkcitygroups",
  "vailgroups",
  "steamboatpackages",
  "crestedbutte",
  "okemo",
  "mountsnow",
  "cascadevacationatlutsen",
  "sb3lakelouise",
  "sb3sunshinevillage",
  "revelstoke",
  "whistlerreservations",
  "seavillasatlutsen",
  "snowriver",
];

class CategoryInfoRow extends React.Component {
  render() {
    return (
      <div className={styles.categoryItems}>
        {this.props.id} {this.props.name} {this.props.quantity}{" "}
        {this.props.total}
      </div>
    );
  }
}
class TravelAgentsInfoRow extends React.Component {
  render() {
    return (
      <div className={styles.travelAgentsItems}>
        {this.props.id} {this.props.name} {this.props.country}{" "}
        {this.props.itineraries} {this.props.total} {this.props.taxes}{" "}
        {this.props.leadDays}
      </div>
    );
  }
}

class ListInfoRow extends React.Component {
  render() {
    return (
      <div className={styles.listItems}>
        {this.props.id} {this.props.name} {this.props.quantity}{" "}
        {this.props.total}
      </div>
    );
  }
}

export default class BookingWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resort: "",
      startDate: "",
      endDate: "",
      resource: {
        packages: [],
        products: {
          categories: [],
          list: [],
          total: "",
        },
        purchase_range: {},
        summary: {},
        travelAgents: [],
      },
    };
  }

  isButtonEnabled() {
    const { resort, startDate, endDate } = this.state;
    return resort !== "" && startDate !== "" && endDate !== "";
  }

  request({ state }) {
    console.log("state", this.state);
    console.log(
      `https://connect.spotlio.com/spotlio/analytics/booking/completed?resort=${this.state.resort}&start=${this.state.startDate}&end=${this.state.endDate}`
    );

    Requester.get(
      `https://connect.spotlio.com/spotlio/analytics/booking/completed?resort=${this.state.resort}&start=${this.state.startDate}&end=${this.state.endDate}`
    ).then((response) => {
      console.log(response);
      this.setState({ ...this.state, resource: response });

      console.log("response", response);
    });
  }

  render() {
    const { startDate, endDate, resource } = this.state;

    return (
      <div className={styles.wrapperContent}>
        <h2>BOOKING WIDGET</h2>
        <div className={styles.content}>
          <label id="destinations">Choose a destination:</label>
          <select
            className={styles.dropDown}
            onChange={(e) => {
              this.setState({ resort: e.target.value });
            }}
          >
            {availableResorts.map((r, idx) => {
              return <option value={r}>{r}</option>;
            })}
          </select>
          <div className={styles.datePicker}>
            <div className={styles.startDate}>
              Start
              <input
                type="date"
                onChange={(e) => {
                  this.setState({ startDate: e.target.value });
                }}
                value={startDate}
              />
            </div>
            <div>
              End
              <input
                type="date"
                onChange={(e) => {
                  this.setState({ endDate: e.target.value });
                }}
                value={endDate}
              />
            </div>
          </div>

          <button
            className={styles.button}
            onClick={() => this.request(this.state)}
            //disabled={!this.isButtonEnabled()}
          >
            Search
          </button>
        </div>
        <div className={styles.infoWrapper}>
          <>
            {console.log("resource:", resource)}
            <div className={styles.purchaseRange}>
              <strong>PURCHASE RANGE:</strong>{" "}
              {resource?.purchase_range &&
                Object.entries(resource.purchase_range).map(([key, value]) => (
                  <div className={styles.summaryItems}>
                    {key}:{value}
                  </div>
                ))}
            </div>
            <div className={styles.summary}>
              <strong>SUMMARY:</strong>
              {resource?.summary &&
                Object.entries(resource.summary).map(([key, value]) => (
                  <div className={styles.summaryItems}>
                    {key}:{value}
                  </div>
                ))}
            </div>

            <div className={styles.packages}>
              <strong>PACKAGES:</strong>
              {resource?.packages &&
                resource.packages.map((value) =>
                  value ? (
                    <div className={styles.summaryItems}>{value}</div>
                  ) : null
                )}
            </div>
            <div className={styles.products}>
              <strong>PRODUCTS:</strong>
              <div className={styles.totalProducts}>
                <strong>Total:</strong>
                {resource?.products.total}
              </div>
              <strong className={styles.catProducts}>Categories:</strong>{" "}
              {resource?.products?.categories &&
                resource?.products?.categories.map((category, index) => (
                  <CategoryInfoRow {...category} key={`category-${index}`} />
                ))}
              <strong className={styles.listProducts}>List:</strong>{" "}
              {resource?.products?.list &&
                resource?.products?.list.map((l, index) => (
                  <ListInfoRow {...l} key={`list-${index}`} />
                ))}
            </div>
            <div className={styles.travelAgents}>
              <strong>TRAVEL AGENTS:</strong>
              {resource?.travelAgents &&
                resource?.travelAgents.map((l, index) => (
                  <TravelAgentsInfoRow {...l} key={`travelAgents-${index}`} />
                ))}
            </div>
          </>
        </div>
      </div>
    );
  }
}
