import React from "react";
import styles from "./styles.module.scss";

export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titles: [],
      values: [],
    };
  }

  componentDidMount() {
    if (this.props.data) {
      const data = this.props.data;
      let titles = [];
      let values = [];
      Object.keys(data).forEach((title, idx) => {
        titles.push(title.replace("_", " "));
        values.push([]);
        data[title].forEach((value) => {
          values[idx].push(value);
        });
      });
      this.setState({ titles, values });
    }
  }

  renderListBody(values) {
    let body = "";
    let length = 0;
    values.forEach((value) => {
      if (value.length > length) length = value.length;
    });
    for (let index = 0; index < length; index++) {
      let element = "<tr>";
      this.state.values.forEach((value) => {
        element += `<td>${value[index] ? value[index] : ""}</td>`;
      });
      element += "</tr>";
      body += element;
    }
    return body;
  }

  render() {
    const { listTitle } = this.props;
    const { titles, values } = this.state;

    if (titles.length < 0) return null;
    return (
      <div className={styles.list}>
        {listTitle ? <div className={styles.title}>{listTitle}</div> : null}
        <table>
          <thead>
            <tr>
              {titles.map((title, idx) => {
                return <th>{title}</th>;
              })}
            </tr>
          </thead>
          <tbody
            dangerouslySetInnerHTML={{ __html: this.renderListBody(values) }}
          />
        </table>
      </div>
    );
  }
}
