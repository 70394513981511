import { API } from "../utils/definitions";
import auth from "./auth";

class Reviews {
  getReviewById(reviewId) {
    return auth
      .requestWithBearer("get", `${API.REVIEWS}/?idExperience.id=${reviewId}`)
      .then((reviewData) => {
        return Promise.resolve(reviewData);
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  createReview(data) {
    return auth
      .requestWithBearer("post", API.REVIEWS, data)
      .then((reviewData) => {
        return Promise.resolve(reviewData);
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  updateReview(experienceId, data) {
    return auth
      .requestWithBearer("put", `${API.REVIEWS}/${experienceId}`, data)
      .then((reviewData) => {
        return Promise.resolve(reviewData);
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }

  async handleReviewChange(experienceId, reviewData) {
    try {
      const review = await this.getReviewById(experienceId);
      if (!review || !Array.isArray(review) || review.length === 0) {
        const newReviewData = await this.createReview(reviewData);
        if (newReviewData) return newReviewData;
      } else {
        const id = review[0].id;
        const data = {};
        if (reviewData.rate) data.rate = reviewData.rate;
        if (reviewData.comment && reviewData.comment.trim().length > 0)
          data.comment = reviewData.comment;

        const newReviewData = await this.updateReview(id, data);
        if (newReviewData) return newReviewData;
      }
    } catch (error) {
      // console.log("review Issue", error);
    }
  }
}

export default new Reviews();
