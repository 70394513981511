import React from "react";

import Icon from "../../icon";

import styles from "./styles.module.scss";

export default class Rating extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: 0,
    };
  }

  setRating = (rating) =>
    this.setState({ rating }, () => {
      if (this.props.onChange) this.props.onChange(rating);
    });

  render() {
    const { rating } = this.state;
    return (
      <div>
        {[1, 2, 3, 4, 5].map((id) => {
          if (id <= rating) {
            return (
              <Icon
                className={styles.star}
                icon="StarFilledIcon"
                onClick={() => this.setRating(id)}
                key={`filled_star_${id}`}
              />
            );
          } else {
            return (
              <Icon
                className={styles.star}
                icon="StarIcon"
                onClick={() => this.setRating(id)}
                color={"white"}
                key={`start_${id}`}
              />
            );
          }
        })}
      </div>
    );
  }
}
