import React from "react";
import { Route, Switch, Router } from "react-router-dom";

import BaqueiraTutorial from "./containers/tutorials/baqueira";
import Apps from "./containers/apps";
import KPI from "./containers/kpi";
import VilalovingHome from "./containers/vilaloving/home";
import VilalovingRegister from "./containers/vilaloving/register";
import VilalovingLogin from "./containers/vilaloving/login";
import VilalovingRoulette from "./containers/vilaloving/roulette";
import VilalovingParticipants from "./containers/vilaloving/participantSelection";
import VilalovingExperience from "./containers/vilaloving/experienceCompleted";
import VilalovingCompleted from "./containers/vilaloving/vilalovingCompleted";
import BookingWidget from "./containers/bookingWidget";

import history from "./history";
import { APP } from "./utils/definitions";

history.listen((location) => {
  setTimeout(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 0);
});

const routes = [
  <Route
    exact
    key="baqueira-tutorial"
    path={["/baqueira/tutorial", "/tutorial/baqueira"]}
    render={(routeProps) => {
      return <BaqueiraTutorial {...routeProps} />;
    }}
  />,

  <Route
    exact
    key="apps-landing"
    path={["/apps"]}
    render={(routeProps) => {
      return <Apps {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-kpi"
    path={["/kpi"]}
    render={(routeProps) => {
      return <KPI {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-vilaloving-home"
    path={[APP.HOME]}
    render={(routeProps) => {
      return <VilalovingHome {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-vilaloving-register"
    path={[APP.REGISTER]}
    render={(routeProps) => {
      return <VilalovingRegister {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-vilaloving-login"
    path={[APP.LOGIN]}
    render={(routeProps) => {
      return <VilalovingLogin {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-vilaloving-roulette"
    path={[APP.ROULETTE]}
    render={(routeProps) => {
      return <VilalovingRoulette {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-vilaloving-participants"
    path={[`${APP.PARTICIPANTS}/:option?`]}
    render={(routeProps) => {
      return <VilalovingParticipants {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-vilaloving-experience"
    path={[`${APP.EXPCOMPLETED}/:option?`]}
    render={(routeProps) => {
      return <VilalovingExperience {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-vilaloving-completed"
    path={[APP.VLVCOMPLETED]}
    render={(routeProps) => {
      return <VilalovingCompleted {...routeProps} />;
    }}
  />,
  <Route
    exact
    key="spotlio-booking-widget"
    path={"/bookingWidget"}
    render={(routeProps) => {
      return <BookingWidget {...routeProps} />;
    }}
  />,
];

export default class RouterClass extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>{routes}</Switch>
      </Router>
    );
  }
}
