import React from "react";
import Lottie from "react-lottie";
import { Button } from "@material-ui/core";

import animationData from "./loading.json";

import styles from "./styles.module.scss";

export default function SpotlioButton(props) {
  const {
    loading,
    loadingMessage,
    className,
    children,
    onClick,
    disabled,
  } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  let content = children;

  if (loading) {
    if (loadingMessage) {
      content = loadingMessage;
    } else {
      content = (
        <Lottie
          options={defaultOptions}
          height={20}
          width={20}
          isStopped={false}
          isPaused={false}
          className="lottie"
        />
      );
    }
  }

  let finalClassName = className;
  if (loading || disabled) {
    finalClassName = [className, styles.disabled].join(" ");
  }

  return (
    <Button
      className={finalClassName}
      onClick={loading === true || !onClick ? undefined : () => onClick()}
      disabled={loading || disabled}
    >
      {content}
    </Button>
  );
}
