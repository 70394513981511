import React from "react";

import { navigateToCurrentStatus } from "../../../helpers/currentStatus";
import localeAsString from "../../../utils/localeAsString";
import PushHistory from "../../../utils/pushHistory";
import { APP } from "../../../utils/definitions";

import Layout from "../../../components/vilaloving/layout";
import CongratulationAnimation from "../../../components/vilaloving/congratulationAnimation";

import styles from "./styles.module.scss";

export default class VilalovingExperienceCompleted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const { history } = this.props;
    if (history.location.state && history.location.state.redirect) return;
    await navigateToCurrentStatus();
  }

  navigateToRoulette = () => PushHistory(APP.ROULETTE);

  render() {
    return (
      <Layout backgroundColor={"#1F3084"} logged>
        <div className={styles.completedWrapper}>
          <CongratulationAnimation />
          <div className={styles.title}>
            {localeAsString("master_vilaloving_completed_experience")}
          </div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: localeAsString("master_vilaloving_completed_description"),
            }}
          />
        </div>
        <div
          className={styles.bottomCTA}
          style={{ backgroundColor: "#1F3084" }}
        >
          <div className={styles.bottomCTAWrapper}>
            <div className={styles.leave} onClick={this.navigateToRoulette}>
              {localeAsString("master_vilaloving_experience_leave")}
            </div>
            <div className={styles.restart} onClick={this.navigateToRoulette}>
              {localeAsString("master_vilaloving_start_again")}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
