import SecureLS from "secure-ls";

const lss = new SecureLS({ encodingType: "", isCompression: false });
// const lss = new SecureLS({});

// saves item in local storage
const setToLocalStorage = (name, value) => {
  try {
    let aux = JSON.stringify(value);
    lss.set(name, aux);
    return true;
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      console.warn(
        "[SettingsRetriever] Could not persist " + name + " item: ",
        e
      );
    }
    return false;
  }
};

// retrieves item from local storage
const getFromLocalStorage = (name) => {
  let item = lss.get(name);
  if (item) {
    try {
      item = JSON.parse(item);
      return item;
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.warn(
          "[SettingsRetriever] Could not get item " + name + ": ",
          e
        );
      }
      return false;
    }
  } else {
    if (process.env.NODE_ENV === "development") {
      console.warn("[SettingsRetriever] Could not get item " + name);
    }
    return false;
  }
};

// public get from local storage
const getLocalStorage = (key) => {
  return getFromLocalStorage(key);
};

// public set to local storage
const setLocalStorage = (key, value) => {
  return setToLocalStorage(key, value);
};

// public clean project local storage
const clearLocalStorage = () => {
  // setToLocalStorage("s-cart", {});
  // setToLocalStorage("form", {});
  localStorage.clear();
};

const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage,
  removeLocalStorage,
};
