import { getLocalStorage } from "../utils/localStorage";

class Logged {
  isLogged = () => {
    const session = getLocalStorage("session");
    if (session.isLogged) {
      return true;
    }

    return false;
  };

  getSessionToken = () => {
    const session = getLocalStorage("session");
    return session.session.token;
  };

  // comprobar si el token de strapi lleva 15 dias o más almacenado en local storage
  checkIfTokenExpired = () => {
    const session = getLocalStorage("session");
    if (session.session.timestamp) {
      const timestamp = session.session.timestamp;
      const now = new Date().getTime();

      let Difference_In_Time = now - timestamp;
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if (Difference_In_Days > 10) {
        return true;
      }
    }
    return false;
  };

  redirect = () => {
    window.location.href = "/";
  };
}

export default new Logged();
