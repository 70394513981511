import React from "react";
import PushHistory from "../../../utils/pushHistory";

import Layout from "../../../components/vilaloving/layout";
import Input from "../../../components/input";
import Loading from "../../../components/loadingAnimation";

import styles from "./styles.module.scss";
import auth from "../../../helpers/auth";
import { APP } from "../../../utils/definitions";
import { navigateToCurrentStatus } from "../../../helpers/currentStatus";

export default class VilalovingLogin extends React.Component {
  state = {
    email: "",
    password: "",
    error: null,
    validForm: {
      validEmail: false,
      validPassword: false,
    },
    focusedInput: null,
    submit: false,
  };

  async componentDidMount() {
    await navigateToCurrentStatus();
  }

  navigateToRegister = () => PushHistory(APP.REGISTER);

  navigateToRoulette = () => {
    PushHistory(APP.ROULETTE, false, { redirect: true });
  };

  getErrorState = (error = false) => {
    return {
      ...this.state,
      password: "",
      error: error,
      submit: false,
    };
  };

  handleSubmit = async (e) => {
    await this.setState({ submit: true });
    const isFormValid = await this.isFormValid();
    if (!isFormValid) return this.setState({ submit: false });
    const { email, password } = this.state;
    try {
      const loginResult = await auth.loginStrapiUser({ email, password });
      if (loginResult) this.navigateToRoulette();
    } catch (error) {
      this.setState(this.getErrorState("Invalid credentials"));
    }
  };

  isFormValid = async () => {
    let isValid = true;
    await this.validateEmail();
    await this.validatePassword();
    const { validForm } = this.state;
    Object.keys(validForm).forEach((label) => {
      if (validForm[label]) isValid = false;
    });
    return isValid;
  };

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  validateEmail = async () => {
    const { email, validForm } = this.state;
    let validations = { ...validForm };
    let isValid = false;

    if (!email) isValid = true;
    if (typeof email !== "string") isValid = true;
    if (email === 0) isValid = true;
    if (
      //eslint-disable-next-line
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      isValid = true;
    }

    validations.validEmail = isValid;
    await this.setState({ validForm: validations });
  };

  validatePassword = async () => {
    const { password, validForm } = this.state;
    let validations = { ...validForm };
    if (!password || password.length < 6) {
      validations.validPassword = true;
    } else {
      validations.validPassword = false;
    }
    await this.setState({ validForm: validations });
  };

  isButtonReadyToBePressed = (email, password, submit) => {
    let isReady = true;
    if (submit) return false;
    if (email === "") isReady = false;
    if (password === "") isReady = false;
    return isReady;
  };

  render() {
    const {
      email,
      password,
      submit,
      error,
      validForm,
      focusedInput,
    } = this.state;

    return (
      <Layout backgroundColor={"#1F3084"}>
        <div className={styles.formWrapper}>
          <div className={styles.formDescription}>
            Inicia sessió al teu compte de l’app de Vilanova per accedir al
            Vilaloving
          </div>
          <div className={styles.formInputs}>
            <div className={styles.input}>
              <Input
                label="Email*"
                value={email}
                type="email"
                name="email"
                placeholder="example@example.com"
                onChange={this.handleInputChange}
                error={validForm.validEmail}
                helperText="* El correu no és vàlid"
                onBlur={() => this.validateEmail()}
                autoFocus={focusedInput === 0}
                onClick={() => this.setState({ focusedInput: 0 })}
                enterPressed={() => this.setState({ focusedInput: 1 })}
              />
            </div>
            <div className={styles.input}>
              <Input
                label="Contrasenya*"
                placeholder={"Mínim 6 caràcters"}
                type={"password"}
                name="password"
                value={password}
                onChange={this.handleInputChange}
                error={validForm.validPassword}
                helperText="* La contrasenya ha de contenir mínim 6 caràcters"
                onBlur={() => this.validatePassword()}
                autoFocus={focusedInput === 1}
                onClick={() => this.setState({ focusedInput: 1 })}
                enterPressed={() => this.setState({ focusedInput: 2 })}
              />
            </div>
            {error && (
              <div className={styles.error}>
                Les credencials no son correctes.
              </div>
            )}
          </div>
        </div>
        <div className={styles.bottomCTA}>
          <div className={styles.registered} onClick={this.navigateToRegister}>
            Encara no en tens un?&nbsp;<b>Crea el teu compte</b>
          </div>
          <div className={styles.bottomCTAWrapper}>
            <div
              className={
                this.isButtonReadyToBePressed(email, password, submit)
                  ? `${styles.button}`
                  : `${styles.button} ${styles.disabled}`
              }
              onClick={this.handleSubmit}
            >
              {submit ? <Loading /> : "Inicia sessió"}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
