import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./index.scss";

// Props:
// label, value & onChange with a setState of value <3
export default class InputComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };
    this._focus = this._focus.bind(this);
  }

  _focus() {
    this.textInput.focus();
  }

  _unfocus() {
    this.textInput.blur();
  }

  componentDidUpdate() {
    if (this.props.autoFocus) {
      this._focus();
    } else if (this.props.autoFocus === false) {
      this._unfocus();
    }
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.enterPressed) this.props.enterPressed();
  };

  render() {
    const { showPassword } = this.state;

    let type = this.props.type || "text";
    if (this.props.type === "password") {
      if (showPassword) {
        type = "text";
      } else {
        type = "password";
      }
    }
    return (
      <form noValidate onSubmit={this.handleSubmit}>
        <TextField
          inputRef={(input) => {
            this.textInput = input;
          }}
          onClick={() => this._focus()}
          {...this.props}
          className={"vilaloving"}
          variant={"outlined"}
          rowsMax={1}
          type={type}
          autoComplete="off"
          helperText={this.props.error ? this.props.helperText : " "}
          autoFocus={this.props.autoFocus ? this.props.autoFocus : false}
          InputProps={{
            autocomplete: "new-password",
            form: {
              autoComplete: "off",
            },
            endAdornment: this.props.type === "password" && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                >
                  {showPassword ? (
                    <Visibility
                      style={{ color: this.props.error ? "#ff4e8d" : "white" }}
                    />
                  ) : (
                    <VisibilityOff
                      style={{ color: this.props.error ? "#ff4e8d" : "white" }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    );
  }
}
