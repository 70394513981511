import React from "react";

import Icon from "../../icon";

import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import "./index.scss";

const defaultOptions = {
  stopAutoPlayOnHover: true,
  animationSpeed: 500,
  slidesPerPage: 1,
  centered: true,
  keepDirectionWhenDragging: true,
  minDraggableOffset: 0,
  addArrowClickHandler: true,
  arrowLeft: <Icon icon={"feather-chevron-left"} color={"#233950"} />,
  arrowRight: <Icon icon={"feather-chevron-right"} color={"#233950"} />,
  infinite: true,
  responsive: true,
  breakpoints: {
    992: {
      slidesPerPage: 1,
    },
  },
};

export default class BrainhubeuCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      open: false,
    };
  }

  onChange = (value) => {
    this.setState({ value });
  };

  render() {
    const { state, props, onChange } = this;
    const { children, options } = props;
    const { value } = state;

    let allOptions = { ...defaultOptions, ...options };

    return (
      <div className={"carousel"}>
        <Carousel break value={value} onChange={onChange} {...allOptions}>
          {children}
        </Carousel>
      </div>
    );
  }
}
