import auth from "./auth";
import experiences from "./experiences";
import logged from "./logged";
import vilalovings from "./vilalovings";
import { APP } from "../utils/definitions";
import isMobile from "../utils/isMobile";
import PushHistory from "../utils/pushHistory";
import QS from "../utils/queryString";
import history from "../history";

export const checkCurrentStatus = async (homeScreen = false) => {
  try {
    if (!logged.isLogged() || logged.checkIfTokenExpired()) {
      await auth.strapiLogin();
    }
    const { user_id } = QS();
    const localUser = auth.getStrapiUserStorage();

    // si hay user_id del query param y estamos desde un MÓVIL (SOLO EN LA PANTALLA HOME):
    if (user_id) {
      if (!homeScreen || !isMobile()) {
        return navigateToCurrentStatus();
      }

      auth.setQueryParamUser(user_id);
      // si no hay user local storage o existe y es el mismo usuario del local storage, hacemos autologin del user_id. Experience / ruleta.
      if (!localUser || !localUser.id || localUser.id === user_id) {
        const userData = await auth.getStrapiUserById(user_id);
        if (userData) return navigateToCurrentStatus();
      }
    }

    return navigateToCurrentStatus();
  } catch (error) {
    console.log("DDD", error);
  }
};

// todo handle redirect Experience / ruleta
export const navigateToCurrentStatus = async () => {
  try {
    if (!logged.isLogged()) {
      await auth.strapiLogin();
    }
    const currentVilaloving = await vilalovings.getCurrentVilaloving();
    const currentExperience = await experiences.getCurrentExperience();
    const localUser = auth.getStrapiUserStorage();
    if (!localUser || !logged.isLogged()) {
      if (history.location.pathname === APP.LOGIN) {
        return;
      }
      return handleRedirection(APP.REGISTER);
    }

    if (currentVilaloving === null || currentExperience === null) {
      return handleRedirection(APP.ROULETTE, false, { redirect: true });
    }

    if (
      typeof currentExperience === "object" &&
      typeof currentVilaloving === "object"
    ) {
      const expireDate = new Date(currentVilaloving.expired_at).getTime();
      let now = new Date().getTime();
      let distance = expireDate - now;
      // check if currentVilaloving is expired
      if (distance < 0) {
        return handleRedirection(APP.ROULETTE, false, {
          expired: true,
          redirect: true,
        });
      } else {
        return handleRedirection(APP.PARTICIPANTS, false, { redirect: true });
      }
    }

    return handleRedirection(APP.ROULETTE, false, { redirect: true });
  } catch (error) {
    console.log("ERROR", error);
  }
};

const handleRedirection = (path, newTab, state) => {
  if (path && path !== history.location.pathname) {
    PushHistory(path, newTab, state);
  }
};

/* 
  En todas las pantallas (componentDidMount). Home: click accedeix:
  - revisar el estado actual del usuario:
    + si hay user_id del query param y estamos desde un MÓVIL (SOLO EN LA PANTALLA HOME):
      * si existe y es el mismo usuario del local storage, hacemos autologin. Experience / ruleta.
      * si no es el mismo, cogemos el de localstorage. Experience / ruleta.
      * si no hay user local storage, hacemos autologin del user_id. Experience / ruleta.
    + si no hay user_id del query param:
      * mirar si hay user en local storage. Experience / ruleta.
      * si no, al register screen
*/

/*
  √ home: logica inicial, service provider all (de los 3 types).
  √ register: logica inicial y comprobar email duplicado.
  √ participant: logica inicial y service provider all del tipo que sea
  √ login, roulette, experience completed y vilaloving completed: lógica inicial

  √ login y registro: trim y toLowerCase en el email y user
  - lógica del bucle final:
  √  * Limpiar local storage de Experiences y Vilaloving y redirigir a la ruleta
  - log out del user:
  √  * Limpiar TODOS los datos del local storage y redirigir a la home.
  
  √ revisar descuento y reloj
  √ no user duplicated
  
  - añadir disabled a los botones importantes
*/
