import { SERVICE_PROVIDERS_ALL } from "../utils/definitions";
import requester from "./requester";

const serviceProviderIds = {
  activities: {
    ids: ["54413", "120", "506", "54414", "21399", "54415", "47944", "54416"],
  },
  gastronomy: {
    ids: [
      "535",
      "716",
      "6097",
      "21396",
      "47399",
      "54101",
      "54108",
      "54110",
      "54114",
      "54116",
      "54255",
      "54256",
      "54257",
      "54258",
      "52593",
    ],
  },
  shopping: {
    ids: [
      "535",
      "716",
      "6097",
      "21396",
      "47399",
      "54101",
      "54108",
      "54110",
      "54114",
      "54116",
      "54255",
      "54256",
      "54257",
      "54258",
      "52593",
    ],
  },
};
class ServiceProvider {
  serviceProviderList = {};

  async getAll() {
    const types = Object.keys(serviceProviderIds);
    types.forEach((type) => this.getAllByType(type));
  }

  getAllByType(type) {
    if (
      this.serviceProviderList[type] &&
      this.serviceProviderList[type].length > 0
    )
      return this.serviceProviderList[type];

    return requester
      .get(`${SERVICE_PROVIDERS_ALL}`)
      .then((serviceProviderList) => {
        const list = [];
        if (!serviceProviderIds[type] || !serviceProviderIds[type].ids)
          return [];

        let ids = serviceProviderIds[type].ids;
        let total = ids.length;
        for (let index = 0; index < serviceProviderList.length; index++) {
          const sp = serviceProviderList[index];
          if (!ids.includes(sp.id)) continue;
          list.push(sp);
          total--;
          if (total === 0) break;
        }
        this.serviceProviderList[type] = list;
        return Promise.resolve(list);
      })
      .catch((err) => {
        return Promise.reject("An error ocurred");
      });
  }
}

export default new ServiceProvider();
