import React from "react";
import { Container, Grid } from "@material-ui/core";
import QRCode from "react-qr-code";

import Carousel from "../../components/carousel";
import Image from "../../components/image";
import Layout from "../../components/layout";
import Helmet from "../../components/helmet";
import Loader from "./loader";

import QS from "../../utils/queryString";
import getLanguageCode from "../../utils/language";
import Requester from "../../helpers/requester";

import styles from "./apps.module.scss";

export default class Apps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      language_id: QS().language_id,
      destination_id: QS().destination_id || 130,
      destination: {},
      config: {},
      carousel: [],
    };

    if (!(this.state.language_id >= 0)) {
      this.state.language_id = 2;
    }
  }

  componentDidMount() {
    this.request();
  }

  request() {
    Promise.all([
      Requester.get(
        `https://ws.spotlio.com/services.php/destination/Get?destination_id=${this.state.destination_id}&language_id=${this.state.language_id}&device_type=web-app&installation_id=1`
      ),
      Requester.get(
        `https://ws.spotlio.com/services.php/destination/getGlobalConfig?destination_id=${this.state.destination_id}&language_id=${this.state.language_id}&installation_id=1`
      ),
    ]).then((responses) => {
      const destination = responses[0];
      const config = responses[1];
      let carousel = [];

      // Group carousel images into an array
      [1, 2, 3, 4, 5].forEach((idx) => {
        let image = config["webapp_app_picture" + (idx === 1 ? "" : "_" + idx)];
        if (image) carousel.push(image);
      });

      let state = this.state;
      state.destination = destination;
      state.config = config;
      state.loading = false;
      state.carousel = carousel;
      this.setState(state);
    });
  }

  getMultilanguageText = (where, field) => {
    let language = getLanguageCode(this.state.language_id);

    return where[field + "_" + language];
  };

  renderHtml = (string) => {
    return {
      __html: string,
    };
  };

  getQrContent = () => {
    let link = window.location.href;
    if (
      this.state.config.webapp_onelink &&
      this.state.config.webapp_onelink !== ""
    ) {
      link = this.state.config.webapp_onelink;
    }
    return link;
  };

  decodeUrl = (link) => {
    return decodeURIComponent(link);
  };

  getHelmetContent = () => {
    return (
      <React.Fragment>
        <title>
          {this.getMultilanguageText(this.state.config, "webapp_apps_title")}
        </title>
        <link
          rel="icon"
          type="image/png"
          href={this.state.config.webapp_favicon}
        />

        <meta
          name="apple-itunes-app"
          content={`app-id=${this.state.config.appstores_ios_app_id}`}
        />
        <meta
          name="google-play-app"
          content={`app-id=${this.state.config.appstores_android_app_id}`}
        />
        <link
          rel="apple-touch-icon"
          sizes="1024x1024"
          href={this.state.config.webapp_favicon}
        />
        <link
          rel="android-touch-icon"
          sizes="1024x1024"
          href={this.state.config.webapp_favicon}
        />
      </React.Fragment>
    );
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <Layout>
        <Helmet lang={getLanguageCode(this.state.language_id)}>
          {this.getHelmetContent()}
        </Helmet>
        <header
          className={`${styles.header}`}
          style={{
            backgroundImage: `url(${this.decodeUrl(
              this.state.destination.destination_splash_image
            )})`,
          }}
        >
          <div className={styles.headerWrapper}>
            <div className={styles.headerContent}>
              <div className={styles.appIconWrapper}>
                <img
                  className={styles.appIcon}
                  src={this.decodeUrl(this.state.config.icon_app)}
                  alt={this.state.destination.destination_name}
                />
              </div>
              <div className={styles.downloadWrapper}>
                <h1 className={styles.mainTitle}>
                  {this.getMultilanguageText(
                    this.state.config,
                    "webapp_apps_title"
                  )}
                </h1>
                <div className={styles.downloadIcons}>
                  <a
                    className={styles.downloadPlayStore}
                    href={this.decodeUrl(this.state.config.PlayApp_link)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Download from Google Play Store"
                      src="https://res.cloudinary.com/spotlio/image/upload/v1530092501/WebApp/Button_-_Play_Store.svg"
                    />
                  </a>
                  <a
                    className={styles.downloadAppStore}
                    href={this.decodeUrl(
                      this.state.config.appstores_apple_store_url
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Download from Apple App Store"
                      src="https://res.cloudinary.com/spotlio/image/upload/v1530092501/WebApp/Button_-_App_Store.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        <Container maxWidth="lg" className={styles.content}>
          <Grid container>
            <Grid item xs={12} md={6} className={styles.leftSide}>
              {this.state.carousel.length > 0 ? (
                <Carousel>
                  {this.state.carousel.map((image, index) => {
                    return (
                      <React.Fragment key={"carousel_ " + index}>
                        <Image
                          className={styles.slideImage}
                          size={"destination-carousel"}
                          source={image}
                          alt={
                            this.state.destination.destination_name +
                            " " +
                            index
                          }
                        />
                      </React.Fragment>
                    );
                  })}
                </Carousel>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} className={styles.rightSide}>
              <div
                className={styles.contentText}
                dangerouslySetInnerHTML={this.renderHtml(
                  this.getMultilanguageText(
                    this.state.config,
                    "webapp_apps_description"
                  )
                )}
              ></div>
              <div className={styles.qrWrapper}>
                <span className={styles.qrImage}>
                  <QRCode
                    alt="QR Download APP"
                    level={"L"}
                    value={this.getQrContent()}
                    size={184}
                  />
                </span>
                <div className={styles.qrText}>
                  Scan the QR Code to download the App.
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className={styles.socketWrapper}>
          <div className={styles.poweredBySpotlio}>
            <span>Powered by</span>
            <a
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://www.spotlio.com/"
            >
              <img
                alt="Powered By SPOTLIO"
                src="https://ik.imagekit.io/spotlio/logo_spotlio_k6ogcfF-R.png"
              />
            </a>
          </div>
        </div>
      </Layout>
    );
  }
}
